@font-face {
    font-family: "FontAwesome";
    src: url("/Scripts/FontAwesome/webfonts/fa-light-300.woff2");
}

@font-face {
    font-family: 'Corin design';
    src: url('/Assets/Release/site/css/fonts/corin-design.woff2') format('woff2'), url('/Assets/Release/site/css/fonts/corin-design.eot') format('embedded-opentype'), url('/Assets/Release/site/css/fonts/corin-design.woff') format('woff'), url('/Assets/Release/site/css/fonts/corin-design.ttf') format('truetype'), url('/Assets/Release/site/css/fonts/corin-design.svg') format('svg');
    font-weight: 400;
    font-style: normal;
}

.lg-actions .lg-next:before {
    content: '\f054' !important;
    font-family: FontAwesome;
    padding-right: 200px;
    font-size: 35px;
    font-weight: 200;
}

.lg-actions .lg-prev:after {
    content: '\f053' !important;
    font-family: FontAwesome;
    padding-left: 200px;
    font-size: 35px;
    font-weight: 200;
}

.lg-actions .lg-next, .lg-actions .lg-prev {
    background-color: unset !important;
}


.logo-section {
    position: fixed;
    z-index: 999;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-bottom: 1px solid #fff;
    background-color: #000;
    min-height:69px;
}

.logo-section.logged-in {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 800;
  width: 100%;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #fff;
}

.nav-section {
  position: fixed;
  top: 131px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 37px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.nav-section.white-line {
  position: fixed;
  top: 125px;
  width: 100%;
  margin-top: 0px;
  border-top: 1px solid #000;
}

.nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 37px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.nav-link {
  padding: 0px 20px;
  color: #fff;
  font-size: 12px;
}

.nav-link:hover {
  background-color: #000;
  color: #bdb19b;
}

.nav-link.w--current {
  color: #f7e9ce;
}

.nav-link.clearance {
  padding-left: 40px;
  color: #f45c71;
}

.nav-link.clearance.megamenu.red:hover {
  color: #d33b36;
}

.nav-link.megamenu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 37px;
  padding-left: 40px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav-link.megamenu:hover {
  background-color: #fff;
  color: #000;
}

.nav-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1250px;
  width:100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-div-block {
  display: block;
  margin-top: auto;
  margin-right: 0px;
  padding-right: 14px;
  float: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  text-align: right;
}

.account-link {
  margin-left: 5px;
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

.account-link:hover {
  color: #bdb19b;
}

.logo {
  margin-top: -16px;
  margin-bottom: 10px;
}

.footer-top {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #4f4f4f;
}

.top-footer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.top-footer-icon-heading {
  color: #fff;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.top-footer-icon-heading.left {
  text-align: left;
}

.main-footer-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  background-color: #000;
}

.footer-copyright {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #000;
}

.copyright-text {
  color: #fff;
  font-size: 11px;
  text-align: center;
}

.footer-link {
  color: #fff;
  font-size: 12px;
  text-decoration: none;
}

.footer-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.footer-col-div {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-left: 4px;
    padding-right: 4px;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.back-to-top-section {
  padding-top: 20px;
  padding-bottom: 10px;
  text-align: center;
}

.top-link-text {
  margin-top: 10px;
  color: #8d8d8d;
  font-size: 14px;
  text-align: center;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
}

.home-main-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  margin-top: 0px;
  padding-top: 189px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.home-main-section.accounts {
  padding-top: 200px;
  padding-bottom: 60px;
}

.home-main-section.products {
  padding-bottom: 60px;
}

.home-main-section.style {
  padding-top: 167px;
}

.home-main-section.style.downloads {
  padding-bottom: 60px;
}

.home-main-section.loggedin {
  padding-top: 150px;
}

.slider {
  height: 800px;
}

.h1-header {
  width: auto;
  margin-bottom: 20px;
  font-family: 'Perpetua Normal', sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.h1-header.section {
  width: 40%;
  border-bottom: 1px solid #000;
}

.h1-header.section.title-width-30{
    width: 30%;
}

.h1-header.section.about {
    width: 50%;
    margin-top: 0px;
}

.h1-header.section.about.sales {
  width: 60%;
}

.h1-header.section.about.seconds {
  width: 60%;
}

.h1-header.section.carbon {
  width: 40%;
  margin-bottom: 20px;
}

.h1-header.section.get-inspired {
  width: 30%;
}

.h1-header.section.shopping {
  width: 40%;
}

.h1-header.section.create-the-style {
  width: 100%;
  margin-bottom: 10px;
}

.h1-header.contact {
  margin-top: 0px;
  font-family: 'Perpetua Light', sans-serif;
  font-weight: 400;
  letter-spacing: 4px;
}

.h1-header.logged-in-new {
  margin-bottom: 0px;
}

.header-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-container.header-container-v1 {
  padding-top: 20px;
  padding-bottom: 60px;
}

.product-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #000;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 3px;
  text-decoration: none;
  text-transform: uppercase;
}

.product-heading.logged-in {
  margin-top: 0px;
  margin-bottom: 20px;
}

.product-heading.serif {
  padding-top: 10px;
  padding-bottom: 40px;
  font-family: 'Perpetua Bold', sans-serif;
}

.product-heading.product-heading-margin-bottom-v1 {
  margin-top: 10px;
  margin-bottom: 40px;
}

.about-div-block {
  position: absolute;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 97%;
  height: 50px;
  margin-top: 300px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.7);
}

.about-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #000;
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
}

.slider-right-arrow {
  position: static;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  margin-left: 0px;
  float: right;
}

.slider-left-arrow {
  position: static;
  float: left;
}

.product-link-block {
  text-decoration: none;
}

.about-link-block {
  margin-bottom: 0px;
}

.image {
  -webkit-perspective: 0px;
  perspective: 0px;
  -webkit-transform: perspective(0px);
  transform: perspective(0px);
}

.product-div-block {
  width: 300px;
  height: 300px;
}

.heart-icon {
  margin-right: 5px;
}

.logged-in-nav-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: visible;
  height: 24px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #fff;
  background-color: #3c3c3a;
}

.logged-in-nav-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.account-nav-link {
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
}

.account-nav-link.logout {
  margin-right: 13px;
}

.logged-in-nav-div-block {
  width: 1050px;
  height: 24px;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  text-align: right;
}

.search-div-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 1250px;
  width: 100%;
  height: 40px;
  margin-top: 33px;
  margin-right: 0px;
  padding-right: 14px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.search-button {
  background-color: transparent;
}

.search-input {
  width: 65px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  border: 1px none #000;
  border-radius: 0px;
  background-color: transparent;
  color: transparent;
  font-size: 16px;
}

.link-search-div {
  height: 30px;
  margin-top: 10px;
  margin-right: 10px;
}

.search-button-2 {
  position: static;
  display: block;
  overflow: visible;
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 0px;
  background-color: transparent;
  background-image: url('../images/search-icon.jpg');
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: no-repeat;
  background-attachment: scroll;
  color: #000;
}

.product-mini-descriptions {
  margin-top: 10px;
  padding-bottom: 0px;
  color: #000;
  font-size: 12px;
  text-align: center;
}

.product-mini-descriptions.recently-viewed {
  margin-top: 0px;
}

.product-mini-descriptions.left {
  margin-top: 0px;
  text-align: left;
}

.logged-in-image-div-bottom {
  width: 100%;
  height: 800px;
  margin-top: 25px;
  background-image: url('../images/Home-page-logged-in_03.png');
  background-position: 0px 0px;
  background-size: cover;
}

.back-to-top-div {
  position: fixed;
  left: auto;
  top: 50%;
  right: 5%;
  bottom: 0%;
  z-index: 999;
  height: 40px;
}

.search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
}

.promo-section {
  height: 37px;
  background-color: #f45c71;
  color: transparent;
}

.promo-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: 1px solid #fff;
  color: #000;
  font-size: 18px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.main-top-image {
  width: 1250px;
  height: 800px;
  background-color: #989c9f;
  background-image: url('../images/example-bg.png');
  background-position: 0px 0px;
  background-size: 125px;
}

.secondary-page-top-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.secondary-page-top-container.carbon {
  margin-top: 40px;
}

.secondary-page-top-container.shop-the-look {
  margin-top: 15px;
}

.trade-account-btn {
  margin-top: 10px;
  padding-right: 30px;
  padding-left: 30px;
  background-color: #2d7648;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.trade-account-btn:hover {
  background-color: #3c3c3a;
}

.main-description-text {
  width: 100%;
  margin-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  font-family: 'PT Serif', serif;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 1px;
  text-transform: none;
}

.product-div-sub-page {
  width: 100%;
  height: 200px;
  background-color: #989c9f;
}

.product-col {
  margin-bottom: 40px;
}

.floral-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb {
  display: block;
  margin-left: 10px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}

.nav-link-2 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.breadcrumb-link1 {
  padding: 0px 0px 0px 10px;
  font-size: 12px;
  font-weight: 700;
}

.breadcrumb-link1.w--current {
  padding-left: 0px;
  color: #000;
  font-size: 12px;
}

.breadcrumb-link1.live {
  color: #989c9f;
  font-weight: 400;
}

.back-link {
  padding: 0px;
  font-size: 12px;
  font-weight: 700;
}

.breadcrumb-home-icon {
  margin-top: -3px;
  padding-right: 5px;
  text-decoration: none;
}

.accounts-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  padding-right: 200px;
  padding-left: 200px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.accounts-container.new-password {
  margin-top: 40px;
  margin-bottom: 20px;
  padding-right: 100px;
  padding-left: 100px;
}

.accounts-header, .newsletter-header {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 18px;
    text-align: left;
    text-transform: none;
    font-family: 'Perpetua Normal', sans-serif;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.accounts-body-copy {
  width: 100%;
  text-align: center;
}

.register-btn {
  margin-top: 13px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #3c3c3a;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.register-btn:hover {
  background-color: #000;
}

.signin-container {
  margin-top: 60px;
}

.account-field {
  width: 80%;
}

.account-field.password {
  width: 100%;
  margin-bottom: 0px;
}

.form {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  grid-auto-columns: 1fr;
  grid-column-gap: 9px;
  grid-row-gap: 16px;
  -ms-grid-columns: 0.5fr 1fr;
  grid-template-columns: 0.5fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.request-new-password-form {
  margin-top: 20px;
}

._1250w-div {
  display: block;
  width: 1250px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

._1250w-div.with-slider {
  width: 1230px;
  margin-right: auto;
  margin-left: auto;
}

.w-icon-slider-right, .w-icon-slider-left {
    color: #989c9f;
}

._1250w-div.main-footer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

._1250w-div.account-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

._1250w-div.breadcrumb {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

._1250w-div.about-us {
  max-width: 1250px;
  width: 100%;
  margin-bottom: 20px;
  margin-left: auto;
  padding-right: 100px;
  padding-bottom: 20px;
}

._1250w-div.newsletter {
    max-width: 1250px;
    width: 100%;
    margin-bottom: 20px;
    margin-left: auto;
    padding-right: 100px;
    padding-bottom: 20px;
}

._1250w-div.about-us.about-us-center{
    padding-right:0px;
}

._1250w-div.about-us.returns {
    margin-bottom: 60px;
}

._1250w-div.about-us.no-left-menu-v1 {
  padding-right: 0px;
}

._1250w-div.about-us.no-left-menu-v1.no-margin-bottom-v1 {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

._1250w-div.about--copy {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.recently-viewed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.videos {
  height: 350px;
  margin-bottom: 40px;
}

._1250w-div.product {
  margin-bottom: 40px;
}

._1250w-div.careers {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.careers.copy {
  padding-right: 140px;
  padding-left: 140px;
}

._1250w-div.careers.top-image {
  margin-top: -13px;
}

._1250w-div.testimonial {
  padding-right: 140px;
  padding-left: 140px;
}

._1250w-div.news-blog {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.seconds {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.seconds.antiques {
  margin-bottom: 0px;
}

._1250w-div.astor {
  height: auto;
}

._1250w-div.products {
  margin-top: 20px;
  margin-bottom: 20px;
}

._1250w-div.news-blog-featured {
  margin-bottom: 0px;
}

._1250w-div.philanthropy {
  margin-top: 40px;
  margin-bottom: 40px;
  padding-right: 220px;
  padding-left: 220px;
}

._1250w-div.shop-look {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 60px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.style-slide {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  height: 1000px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._1250w-div.furniture {
  max-height: 800px;
  height: auto;
}

._1250w-div.findus {
  margin-top: 25px;
}

.login-col {
  height: 30px;
}

.login-col-filler {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sign-in-col {
  display: block;
  padding-left: 125px;
}

.about-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 160px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.page-nav-header {
  margin-top: 0px;
  margin-bottom: 20px;
  color: #3c3c3a;
  font-size: 16px;
  text-transform: uppercase;
  text-align:left;
}

.secondary-nav {
  margin-bottom: 5px;
  color: #3c3c3a;
  text-decoration: none;
}

.secondary-nav.current {
  margin-bottom: 5px;
  padding-bottom: 0px;
  border-bottom: 1px solid #3c3c3a;
  text-decoration: none;
}

.secondary-nav-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.secondary-nav-col.bottom {
  padding-left: 40px;
}

.secondary-nav-col.left-menu-v1 {
  padding-left: 95px;
}

.secondary-nav-col-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.secondary-nav-col-right.policies {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.secondary-nav-col-right.left-align {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.secondary-nav-col-right.left-align.by-transport {
  padding-left: 195px;
}

.secondary-nav-col-right.astor {
  margin-bottom: 20px;
}

.secondary-nav-col-right.top-text-pad-left-v1 {
  padding-left: 0px;
}

.secondary-nav-col-right.top-heading-pad-v1 {
  padding-right: 40px;
  padding-left: 40px;
}

.header-section.contact {
    padding-left: 0px;
}

.sub-header {
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
}

.about-story-copy {
  width: 60%;
  margin-bottom: 10px;
  font-style: normal;
  text-align: center;
}

.about-story-copy.fairs {
  margin-top: 20px;
}

.about-story-copy.withoutheader {
  width: 100%;
  margin-top: 10px;
}

.about-story-copy.withoutheader.policies {
  text-align: left;
}

.about-story-copy.withoutheader.left-align {
  text-align: left;
}

.about-story-copy.withoutheader.left-align.findus {
  margin-top: 40px;
}

.about-story-copy.checkout {
  margin-top: 20px;
}

.about-story-copy.testimonial {
  margin-bottom: 40px;
}

.about-story-copy.philanthropy {
  margin-top: 20px;
}

.about-story-copy.carbon {
  margin-bottom: 0px;
}

.about-story-copy.margin-bottom-v1 {
  margin-bottom: 20px;
}

.testimonial-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #3c3c3a;
  border-bottom: 1px none #3c3c3a;
}

.testimonial-slider {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  height:100%;
}

.testimonial-slider-icon {
  color: #989c9f;
}

.testimonial-slide {
  width: 100%;
  height: 50%;
  background-color: #fff;
}

.testimonial-header {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
}

.testimonial-header.policies {
  margin-top: 0px;
  font-family: 'Perpetua Light', sans-serif;
  font-weight: 300;
  text-align: left;
  letter-spacing: 4px;
}

.testimonial-header.policies.text-page-header-margin-v1 {
  margin-bottom: 30px;
}

.testimonial {
  display: block;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.recently-viewed-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid none none;
  border-width: 1px 0px 0px;
  border-color: #3c3c3a #000 #000;
}

.recently-viewed-col-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  height: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-code {
  margin-bottom: 0px;
  color: #000;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.product-code.left {
  font-weight: 400;
  text-align: left;
}

.product-stock {
  color: #000;
  font-size: 12px;
  font-style: italic;
  font-weight: 700;
  text-align: center;
}

.product-stock.left {
  font-style: normal;
  font-weight: 400;
  text-align: left;
}

.w-slider-nav {
    padding-top:35px;
    font-size:10px;
}

.product-detail-slider-nav {
    padding-top: 10px;
    font-size: 10px;
    position: relative;
}

.home-main-section .w-slider-nav {
    padding-top: 20px;
}

.recently-viewed-price {
  color: #000;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.fairs-and-events-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 40px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #3c3c3a;
}

.event-post-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.event-location {
  font-size: 12px;
}

.event-date {
  font-size: 12px;
}

.event-name {
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 18px;
  text-transform: uppercase;
}

.slavery-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #3c3c3a;
}

.product-filter-col1 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-filter-col2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.product-filter-heading {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}

.dropdown {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
}

.filter-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.filter-form-name {
  width: 100px;
  font-size: 18px;
  font-weight: 400;
}

.select-field {
  display: block;
  width: 200px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.new-btn {
  width: 150px;
  margin-top: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border: 1px solid #000;
  border-radius: 0px;
  background-color: #fff;
  color: #000;
  font-size: 12px;
  text-align: center;
}

.product-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-column.antique {
  margin-bottom: 20px;
}

.heart {
  position: absolute;
  top: 270px;
  right: 10px;
}

.clear-all-text-link {
  color: #3c3c3a;
  font-size: 14px;
  text-decoration: none;
}

.account-application-section {
  margin-top: auto;
  padding-top: 200px;
  padding-bottom: 40px;
}

.company-details-section {
  margin-bottom: 40px;
  padding-top: 40px;
  border-top: 1px solid #000;
}

.company-field-names {
  color: #989c9f;
  font-weight: 400;
  text-align: left;
}

.company-field-names.indent {
  margin-left: 10px;
}

.column-form {
  margin-top: 10px;
}

.column-form.returns {
  margin-top: 40px;
}

.form-select-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  text-align: center;
}

.submit-btn {
  width: 200px;
  margin-top: 20px;
  background-color: #3c3c3a;
  text-transform: uppercase;
}

.submit-btn:hover {
  background-color: #000;
}

.shopping-section {
  padding-top: 184px;
  padding-bottom: 60px;
}

.price-headers {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  text-transform: uppercase;
}

.price-headers.right {
  margin-bottom: 0px;
  font-size: 14px;
  text-align: center;
}

.price-headers.price-header-left {
  text-align: left;
}

.header-price-col {
  border-bottom: 1px solid #000;
}

.header-price-col.wishlist {
  border-top: 1px solid #000;
}

.price {
  margin-top: 70px;
  font-weight: 700;
  text-align: center;
}

.price.total {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}

.price-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-bottom: 1px solid #000;
}

.price-column.no-bottom-border-v1 {
  border-bottom-style: none;
}

.price-div {
  margin-left: 20px;
}

.price-form {
  margin-top: 4px;
}

.reference-type {
  margin-top: 10px;
  color: #989c9f;
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
}

.reference-type.print {
  padding-left: 5px;
  float: right;
}

.reference-type.print.checkout-print {
  margin-top: 10px;
}

.reference-type.checkout-order-notes {
  margin-top: 20px;
}

.reference-type.wl-edit-btn-v1 {
  margin-left: 10px;
}

.price-col-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 80px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
}

.price-col-2.shopping-basket {
  padding-bottom: 87px;
}

.price-col-2.no-bottom-border-v1 {
  border-bottom-style: none;
}

.quantitiy-div-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #989c9f;
}

.quantitiy-div-box.add-basket {
  width: 30%;
  height: 40px;
}

.quantitiy-div-box.add-basket.returns {
  width: 15%;
}

.quantitiy-div-box.wishlist {
  width: 100px;
}

.price-col-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 67px 0px 80px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #000;
}

.price-col-3.product {
  padding-bottom: 73px;
}

.quantitiy-text {
  font-size: 14px;
  text-align: center;
}

.total-price-container {
  padding-top: 20px;
  padding-bottom: 20px;
}

.print-icon {
  position: static;
  margin-top: 10px;
  float: right;
}

.print-icon.checkout-print {
  margin-top: 10px;
}

.button-check-out {
  width: 200px;
  margin-top: 20px;
  float: right;
  background-color: #2d7648;
  text-align: center;
  text-transform: uppercase;
}

.button-check-out:hover {
  background-color: #3c3c3a;
}

.button-check-out.cont-shop {
  margin-top: 20px;
  margin-right: 20px;
  background-color: #3c3c3a;
}

.button-check-out.cont-shop:hover {
  background-color: #000;
}

.button-check-out.cont-shop.add-item-btn {
  width: 100px;
  margin-top: -10px;
  margin-left: 10px;
  padding-top: 8px;
  padding-bottom: 7px;
  line-height: 15px;
}

.button-check-out.contact {
  margin-top: 25px;
  margin-left: 0px;
  float: left;
  background-color: #3c3c3a;
}

.button-check-out.contact:hover {
  background-color: #000;
}

.button-check-out.contact.returns {
  margin-top: 10px;
}

.button-check-out.returns {
  margin-top: 0px;
  margin-bottom: 40px;
  float: left;
}

.button-check-out.wishlist {
  width: 250px;
  height:40px;
  margin-top: 0px;
}

.button-check-out.wishlist-new {
  margin-top: 0px;
  background-color: #3c3c3a;
}

.button-check-out.wishlist-new:hover {
  background-color: #000;
}

.po-number-text-field {
  width: 35%;
}

.select-order-type-field {
  width: 35%;
}

.order-form {
  /*display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;*/
  display: block;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

._1100w-div-block {
  width: 1100px;
  margin-bottom: 40px;
}

.add-to-basket-col-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 0px;
  padding-left: 21px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._100px-div-block {
  margin-bottom: 5px;
}

.add-to-basket-col-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 8px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.add-heading {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
  text-transform: uppercase;
}

.add-product-name {
  color: #989c9f;
  font-size: 18px;
}

.add-product-code {
  color: #989c9f;
  font-size: 12px;
}

.add-product-price {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #3c3c3a;
  font-size: 16px;
  font-weight: 400;
}

.add-product-description {
  color: #989c9f;
  font-size: 12px;
  line-height: 16px;
}

.add-product-read-more-link {
  margin-top: -10px;
  margin-bottom: 0px;
  color: #989c9f;
  font-size: 12px;
}

.add-product-dimensions {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #989c9f;
  font-size: 12px;
}

.add-product-out-of-stock {
  font-size: 16px;
  font-weight: 700;
}

.add-product-email-txt {
  margin-left: 3px;
  color: #989c9f;
  font-size: 12px;
  text-decoration: none;
}

.email-div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-decoration: none;
}

.add-product-pre-order-div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.add-basket-pre-order-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  margin-left: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #3c3c3a;
  text-align: center;
  text-transform: uppercase;
}

.add-basket-pre-order-btn:hover {
  background-color: #000;
}

.add-product-wishlist-btn {
  margin-bottom: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #989c9f;
  background-color: #fff;
  color: #989c9f;
  text-align: center;
}

.add-product-view-range-btn {
  min-height: 40px;
  height:auto;
  margin-bottom: 20px;
  background-color: #2d7648;
  text-align: center;
  text-transform: uppercase;
}

.add-product-view-range-btn:hover {
  background-color: #3c3c3a;
}

.add-product-view-range-btn.grey {
  margin-right: 10px;
  background-color: #3c3c3a;
}

.add-product-view-range-btn.grey:hover {
  background-color: #000;
}

.add-basket-specification-links {
  margin-top: -5px;
  color: #989c9f;
  font-size: 12px;
  text-decoration: none;
}

.add-basket-specification-links:hover {
  color: #3c3c3a;
}

.specifications-heading {
  margin-bottom: 10px;
  border-bottom: 1px solid #989c9f;
  text-decoration: none;
}

.career--position-links {
  margin-top: 10px;
  color: #075c9c;
  font-size: 16px;
  text-decoration: none;
}

.news-blog-post-section {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid none none;
  border-width: 1px 0px 0px;
  border-color: #3c3c3a;
}

.text-block {
  font-size: 12px;
}

.news-blog-title {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 20px;
  text-transform: uppercase;
}

.news-blog-copy {
  width: 60%;
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.news-blog-read-more-btn {
  height: 40px;
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #3c3c3a;
  text-transform: uppercase;
}

.news-blog-read-more-btn:hover {
  background-color: #000;
}

.sales-column {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sales-column.left {
  padding-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sales-column.right {
  padding-left: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sales-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-bottom: 40px;
  padding-right: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.sales-name {
  margin-top: 10px;
  color: #000;
  font-size: 12px;
}

.sales-job-title {
  color: #3c3c3a;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
}

.seconds-copy {
  width: 60%;
  margin-bottom: 40px;
  text-align: center;
}

.seconds-copy.breaker {
  margin-top: 40px;
}

.seconds-column-div {
  width: 100%;
}

.back-to-top-link-block {
  text-decoration: none;
}

.dropdown-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 37px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown-wrapper.megamenu:hover {
  background-color: #fff;
}

.dropdown-menu-div---accessories {
  position: absolute;
  display: none;
  width: 100%;
  height: 384px;
  margin-top: 162px;
  padding: 20px 325px;
  background-color: #fff;
  box-shadow: 0 11px 19px 0 rgba(0, 0, 0, 0.5);
}

.mega-menu-heading {
  margin-top: 0px;
  margin-bottom: 5px;
  font-size: 14px;
}

.mega-menu-heading.red {
  margin-bottom: 10px;
  color: #d33b36;
}

.mega-menu-heading.red.center {
  margin-bottom: 0px;
  text-align: center;
}

.mega-menu-heading.center {
  text-align: center;
}

.mega-menu-heading.floral {
  margin-bottom: 20px;
  margin-left: 10px;
}

.mega-menu-heading.lighting {
  margin-top: 40px;
}

.mega-menu-link {
  color: #3c3c3a;
  font-size: 12px;
  text-decoration: none;
}

.mega-menu-link.red {
  color: #d33b36;
}

.mega-menu-link.floral-links {
  padding-bottom: 20px;
}

.megamenu-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.megamenu-col.secondary {
  margin-top: 20px;
}

.megamenu-col.accessories {
  padding-right: 0px;
  padding-left: 0px;
}

.dropdown-menu-div---clearance {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 162px;
  padding: 40px 220px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.6);
}

.dropdown-menu-div---floral {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 162px;
  padding: 20px 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.6);
}

.dropdown-menu-div---lighting {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 162px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.6);
}

.dropdown-menu-div---mirrors {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 162px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.6);
}

.search-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 10px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.search-bar {
  width: 80%;
  margin-top: 25px;
  margin-right: 10px;
  margin-left: 20px;
  border: 1px none #000;
  font-size: 30px;
  font-weight: 700;
}

.search-button-main {
  background-color: #000;
}

.search-section {
  position: relative;
  left: 0;
  top: 10px;
  right: 0;
  bottom: auto;
  z-index: 999;
  display: none;
  margin-top: auto;
  margin-bottom: 15px;
  min-height: 100px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.6);
}

.contact-div {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 40px;
  padding-right: 0px;
}

.contact-col1 {
  padding-right: 10px;
}

.column {
  display: block;
  padding-left: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.contact-col-right {
  display: block;
  padding-left: 0px;
  clear: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.by-transport-section {
  padding-top: 0px;
  padding-bottom: 0px;
  border-top: 1px solid #000;
}

.div-block {
  width: 100%;
  height: 40px;
}

.download-link {
  margin-bottom: 10px;
  color: #075c9c;
  text-decoration: none;
}

.columns {
  width: 100%;
}

.columns-2 {
  width: 100%;
}

.columns-3 {
  width: 100%;
}

.columns-4 {
  width: 100%;
}

.columns-5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.account-top-div-block {
  display: block;
  width: 1250px;
  height: auto;
  margin-top: auto;
  clear: none;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-grid-row-align: auto;
  align-self: auto;
  -webkit-box-ordinal-group: 1;
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  text-align: right;
}

.div-block-2 {
  width: 250px;
  height: 10px;
}

.search-block {
  width: 100%;
}

.search-close {
  margin-top: 10px;
  margin-left: auto;
  padding-left: 0px;
  float: right;
}

.form-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

._600x600 {
  width: 600px;
  height: 600px;
}

._100px {
  width: 100px;
  height: 100px;
}

._1250w-div-logged-out {
  max-width: 1250px;
  width:100%;
}

.image-2 {
  width: 200px;
}

.image-3 {
  width: 200px;
}

.wishlist {
  margin-bottom: 10px;
}

.your-account-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.personal-container {
  margin-bottom: 40px;
}

.order-div {
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 1px solid #000;
}

.order-div.no-bottom-border-v1 {
  border-bottom-style: none;
}

.dropdown-menu-div---christmas {
  position: absolute;
  display: none;
  width: 100%;
  margin-top: 162px;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.47);
}

.lightbox-div {
  position: absolute;
  z-index: 1000;
  display: none;
  width: 100%;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.white-light-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 45%;
  height: 400px;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: #fff;
}

.lightbox-close-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  margin-top: 6px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.lightbox-header {
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 400;
}

.lightbox-copy {
  color: #3c3c3a;
  font-size: 12px;
}

.lightbox-form {
  margin-top: 19px;
}

.create-new-wishlist-btn {
  width: 50%;
  margin-right: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #3c3c3a;
  background-color: #fff;
  color: #3c3c3a;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}

.add-to-wish-list-btn {
  width: 48%;
  padding-top: 5px;
  padding-bottom: 5px;
  float: right;
  border-style: solid;
  border-width: 1px;
  border-color: #3c3c3a;
  background-color: #3c3c3a;
  font-size: 10px;
  text-align: center;
  text-transform: uppercase;
}

.load-more-section {
  margin-bottom: 60px;
}

.load-more-container {
  display: block;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.load-more-text {
  color: #989c9f;
  font-size: 12px;
}

.load-more-btn {
  margin-top: 10px;
  padding-right: 80px;
  padding-left: 80px;
  background-color: #3c3c3a;
  font-weight: 700;
  text-transform: uppercase;
}

.specifications-link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 5px;
  padding-right: 5px;
  padding-bottom: 3px;
  padding-left: 5px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid #989c9f;
  color: #3c3c3a;
  text-decoration: none;
}

.down-arrow {
  position: relative;
  clear: none;
}

.pdf-div-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.add-to-wish-list-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.light-grey-text {
  color: #989c9f;
}

.add-item-box {
  width: 200px;
  height: 30px;
  margin-right: 10px;
}

.print-div {
  margin-bottom: 10px;
}

.wishlist-container-2 {
  margin-top: 40px;
}

.continue-shopping-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 92px;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.new-badge {
  position: absolute;
  margin-top: 1px;
  padding: 10px 8px 7px 10px;
  background-color: #3c3c3a;
}

.new-txt {
  font-family: 'Perpetua Light', sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
}

.sales-col {
  margin-bottom: 40px;
}

.style-col {
  padding-right: 10px;
  padding-left: 10px;
}

.style-link-block {
  width: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.style-container {
  margin-bottom: 60px;
}

.style-buy-btn {
  width: 45%;
  background-color: #2d7648;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
}

.style-buy-btn.grey {
  background-color: #3c3c3a;
}

.style-buy-btn.main {
  width: 200px;
}

.style-buy-btn.main.grey {
  margin-right: 10px;
}

.div-block-3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.print-text {
  margin-right: 10px;
  color: #989c9f;
}

.download-link-div {
  text-align: center;
  text-decoration: none;
}

.download-col {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px solid #989c9f;
}

.current-catalogue-section {
  display: block;
  padding-top: 100px;
  padding-bottom: 100px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid #000;
}

.catalogue-div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.catalogue-txt {
  margin-left: 20px;
}

.close-link-block {
  position: static;
  float: right;
  clear: none;
}

.contact-div-block {
  width: 49%;
  height: 300px;
  float: left;
  background-color: #989c9f;
}

.contact-div-block.right {
  width: 49%;
  float: right;
}

.contact-text-field {
  margin-left: 10px;
}

.email-link, .findus a {
    color: #075c9c;
    text-decoration: none;
}

.line-break {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #3c3c3a;
}

.line-break._1250w {
  width: 1230px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.home-page-div-block-header {
  margin-top: 40px;
}

.faq-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: none none solid;
  border-width: 1px;
  border-color: #989c9f;
  color: #3c3c3a;
  text-align: left;
  text-decoration: none;
}

.question {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 10px;
  font-size: 14px;
}

.faq-arrow {
  clear: right;
}

.faq-answer-div {
  display: none;
  width: 100%;
  height: auto;
  margin-top: -10px;
  margin-bottom: 10px;
  padding: 10px;
  border-style: none none solid;
  border-width: 1px;
  border-color: #000 #000 #989c9f;
  background-color: transparent;
  text-align: left;
}

.return-note {
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: left;
}

.return-item-action {
  color: #777;
  font-size: 12px;
  text-align: left;
}

.checkout-item-tbx {
  margin-bottom: 0px;
  line-height: 32px;
}

.image-4 {
  width: 1250px;
}

.create-the-style-slider {
  width: 100%;
  height: 100%;
}

.slide {
  background-image: url('../images/Screen-Shot-2019-07-18-at-20.40.35.png');
  background-position: 0px 0px;
  background-size: cover;
}

.create-the-style-arrows {
  color: #000;
}

.create-the-style-arrows.right {
  position: absolute;
  left: auto;
  right: -79px;
  margin-left: auto;
  padding-left: 0px;
}

.create-the-style-arrows.left {
  left: -79px;
}

.plus-sign {
  display: block;
  clear: none;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 24px;
}

.paragraph {
  margin-bottom: 0px;
}

.minus-sign {
  display: none;
  clear: none;
  font-family: Georgia, Times, 'Times New Roman', serif;
  font-size: 24px;
}

.image-5 {
  width: 1250px;
  height: 800px;
}

.specifications-div {
  display: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.specifications {
  display: none;
  color: #989c9f;
  font-size: 12px;
  line-height: 16px;
}

.image-6 {
  height: 350px;
}

.image-7 {
  height: 350px;
}

.image-8 {
  height: 350px;
}

.image-9 {
  height: 350px;
}

.image-10 {
  height: 350px;
}

.image-11 {
  height: 350px;
}

.downloads-link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 1000px;
  height: auto;
  margin-bottom: 20px;
  padding: 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-top: 1px none #989c9f;
  border-bottom: 1px solid #989c9f;
  color: #3c3c3a;
  text-decoration: none;
}

.download-1000-div {
  display: none;
  width: 1000px;
  height: auto;
  margin-bottom: 40px;
}

.colour-filter-squares {
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #989c9f;
}

.text-span {
  font-style: italic;
}

.text-span-2 {
  font-style: italic;
}

.h2-v1 {
  margin-top: 10px;
  font-family: 'Perpetua Normal', sans-serif;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 6px;
  text-transform: uppercase;
}

.wl-edit-btn-v1 {
  color: #989c9f;
  text-transform: none;
}

.span-normal-v1 {
  font-weight: 400;
}


.account-form-block.w-form.extend-to-490{
    max-width:490px;
    width:100%;
}

@media (max-width: 1203px){
    .nav-link {
        padding: 0px 10px;
    }

}

@media (max-width: 991px) {
    .nav-section {
    top: 125px;
  }

  .nav-link.megamenu {
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: left;
  }
  .nav-menu {
    width: 100%;
    background-color: #3c3c3a;
  }
  .account-div-block {
    position: fixed;
    left: 0%;
    top: 0.5%;
    right: 0%;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: auto;
    clear: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .logo {
    margin-top: 19px;
  }
  .main-footer-section {
    padding-left: 20px;
  }
  .home-main-section {
    padding-top: 156px;
  }
  .home-main-section.products {
    padding-top: 200px;
  }
  .home-main-section.style {
    padding-top: 124px;
  }
  .home-main-section.style.downloads {
    padding-right: 40px;
    padding-left: 40px;
  }
  .home-main-section.add-to-basket {
    padding-top: 200px;
  }
  .home-main-section.ranges {
    padding-top: 195px;
  }
  .slider {
    height: 500px;
  }
  .h1-header {
    line-height: 30px;
  }
  .product-heading {
    margin-top: 3px;
    margin-bottom: 7px;
    line-height: 18px;
  }
  .about-div-block {
    width: 95%;
    margin-top: 171px;
  }
  .account-nav-link {
    width: auto;
  }
  .account-nav-link.logout {
    width: auto;
  }
  .search-div-block {
    width: 100%;
  }
  .main-top-image {
    width: 100%;
    height: 500px;
  }
  .register-btn {
    margin-top: 10px;
  }
  ._1250w-div {
    width: 100%;
  }
  ._1250w-div.with-slider {
    width: 100%;
    height: 100%;
  }
  ._1250w-div.about-us {
    width: 100%;
  }
  ._1250w-div.videos {
    height: auto;
  }
  ._1250w-div.style-slide {
    width: 600px;
    height: 600px;
  }
  ._1250w-div.furniture {
      height: auto;
  }
  .sign-in-col {
    padding-left: 60px;
  }
  .about-section {
    padding-right: 0px;
    padding-left: 0px;
  }
  .secondary-nav-col.left-menu-v1 {
    padding-left: 20px;
  }
  .testimonial-slide {
    padding-right: 100px;
    padding-left: 100px;
  }
  .product-filter-col1 {
    margin-bottom: 20px;
    padding-right: 0px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .filter-form {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .company-field-names {
    font-size: 12px;
  }
  .company-field-names.indent {
    font-size: 12px;
  }
  ._1100w-div-block {
    width: auto;
  }
  .account-top-div-block {
    width: 100%;
    margin-top: 0px;
  }
  ._600x600 {
    width: auto;
    height: auto;
  }
  ._1250w-div-logged-out {
    width: 100%;
    margin-top: 0px;
    padding-right: 20px;
  }
  .image-4 {
    width: auto;
  }
  .image-5 {
    width: 100%;
    height: 500px;
  }
  .image-6 {
    height: 300px;
  }
  .image-7 {
    height: 300px;
  }
  .image-8 {
    height: 300px;
  }
  .image-9 {
    height: 300px;
  }
  .image-10 {
    height: 300px;
  }
  .image-11 {
    height: 300px;
  }
  .downloads-link-block {
    margin-right: 40px;
    margin-left: 40px;
  }

  .hamburger-menu-button.w--open {
    background-color: transparent;
  }
  .slide-nav {
    display: none;
  }
}

.links-no-wrap-on-white-space a {
    white-space: pre;
}

@media (max-width: 767px) {
    .main-footer-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .footer-link {
    margin-right: 0px;
  }
  .footer-col-div {
    position: static;
    width: 100%;
    clear: none;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-align-content: flex-start;
    -ms-flex-line-pack: start;
    align-content: flex-start;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .slider {
    height: 400px;
  }
  .header-container {
    padding: 20px;
  }
  .about-div-block {
    position: absolute;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    width: 97%;
    margin-top: 275px;
  }
  .about-link-block {
    margin-bottom: 20px;
  }
  .product-div-sub-page {
    width: 200px;
  }
  .product-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .accounts-header {
    margin-top: 20px;
  }
  .account-field {
    width: 100%;
    margin-left: 0px;
  }
  ._1250w-div.with-slider {
    height: 400px;
  }
  ._1250w-div.shop-look {
    margin-top: 0px;
  }
  ._1250w-div.style-slide {
    width: 450px;
    height: 450px;
  }
  .login-col {
    height: 0px;
  }
  .sign-in-col {
    padding-right: 60px;
    padding-left: 86px;
  }
  .secondary-nav-col-right.policies {
    margin-top: 20px;
  }
  .secondary-nav-col-right.left-align {
    margin-top: 20px;
  }
  .recently-viewed-col-div {
    margin-top: 0px;
  }
  .product-filter-col1 {
    margin-top: -13px;
    margin-bottom: 0px;
    padding-right: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .product-filter-heading {
    margin-right: 10px;
    line-height: 24px;
  }
  .product-column {
    margin-bottom: 40px;
  }
  .clear-all-text-link {
    width: 200px;
    margin-left: 10px;
    line-height: 18px;
  }
  .account-application-section {
    padding-right: 40px;
    padding-left: 40px;
  }
  .company-details-section {
    padding-right: 40px;
    padding-left: 40px;
  }
  .add-to-basket-col-left {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .add-to-basket-col-right {
    padding-right: 40px;
    padding-left: 40px;
  }
  .column {
    padding-left: 0px;
  }
  .image-2 {
    width: auto;
  }
  .image-3 {
    width: auto;
  }
  .personal-container {
    padding-right: 40px;
    padding-left: 40px;
  }
  .contact-text-field {
    margin-left: 10px;
  }
  .contact-text-field.mobile {
    margin-left: 0px;
  }
  .home-col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .image-12 {
    max-width: 100%;
  }
  .about-us-image {
    margin-bottom: 20px;
  }
}

@media (max-width: 479px) {
  .logo-section.logged-in {
    height: 140px;
  }
  .nav-section {
    top: 156px;
  }

   .padding-0-maxwidth-479 {
       padding: 0 !important;
   }
   
   ._1250w-div.testimonial {
        padding-right: 80px;
        padding-left: 80px;
   }

    .no-margin-bottom-maxWidth-479 {
        margin-bottom:0;
    }

  .logo {
    width: 80%;
  }
  .home-main-section {
    padding-top: 159px;
  }
  .slider {
    height: 250px;
  }
  .h1-header.section {
    width: 100%;
  }

.h1-header.section.title-width-30 {
    width: 100%;
}

  .h1-header.section.about {
    width: 120%;
  }
  .h1-header.section.about.sales {
    width: 100%;
  }
  .h1-header.section.about.seconds {
    width: 100%;
  }
  .h1-header.section.carbon {
    width: 100%;
  }
  .h1-header.section.get-inspired {
    width: 50%;
  }
  .about-div-block {
    margin-top: 130px;
  }
  
  ._1250w-div.with-slider {
    height: 250px;
  }
  ._1250w-div.style-slide {
    width: 300px;
    height: 300px;
  }
  .secondary-nav-col-right {
    margin-top: 25px;
  }
  .sub-header {
    line-height: 24px;
  }
  .product-filter-col1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .product-filter-col2 {
    padding-right: 20px;
    padding-left: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .filter-form {
    margin-top: 22px;
    padding-left: 10px;
  }
  .select-field {
    width: 150px;
  }
  .question {
    padding-bottom: 10px;
    line-height: 18px;
  }
  .brand {
    margin-top: 48px;
    text-align: center;
  }
  .brand.w--current {
    margin-top: 44px;
  }
  .mobile-col {
    margin-bottom: -25px;
  }
  .column-2 {
    display: none;
  }
  .column-3 {
    display: none;
  }
}
@media (max-width: 374px) {
    .checkbox-field.w-checkbox.w-clearfix.signup-terms-cbx-wrapper {
        display: flex;
    }

    .reference-type.edit-wishlist-name-btn {
        display: flex;
    }

    .pf-edit-wishlist {
        width: 165px;
    }

    .w-clearfix.w-container.container-wishlist {
        padding: 5px;
    }

    .wishlist-image-td {
        padding:0 !important;
    }

    table.cart-table tbody > tr > td,
    table.cart-table tbody > tr > th {
        padding: 5px;
    }

    table.cart-table.pf-checkout-cart-table tbody > tr > td {
        padding: 5px !important;
    }

    .product-code-margin-top-0 {
        margin-top: 0;
    }
}

@media (max-width: 350px) {
    .w-container.w-container-0-padding-l-r-max-width-350 {
        padding-left: 0;
        padding-right: 0;
    }
}

    @font-face {
        font-family: 'Perpetua Normal';
        src: url('../fonts/PERTILI.TTF') format('truetype');
        font-display:swap;
        font-weight: 400;
        font-style: normal;
    }

    @font-face {
        font-family: 'Perpetua Bold';
        src: url('../fonts/pertibd.ttf') format('truetype');
        font-weight: 700;
        font-display:swap;
        font-style: normal;
    }

    @font-face {
        font-family: 'Perpetua Light';
        src: url('../fonts/PerpetuaTitlingMTLight2.ttf') format('truetype');
        font-weight: 300;
        font-display:swap;
        font-style: normal;
    }