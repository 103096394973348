#cookie-bar {background:#111111; height:auto; line-height:24px; color:#eeeeee; text-align:center; padding:3px 0;}
#cookie-bar.fixed {position:fixed; top:0; left:0; width:100%;}
#cookie-bar.fixed.bottom {bottom:0; top:auto;}
#cookie-bar p {margin:0; padding:0;}
#cookie-bar a {color:#ffffff; display:inline-block; border-radius:3px; text-decoration:none; padding:0 6px; margin-left:8px;}
#cookie-bar .cb-enable {background:#007700;}
#cookie-bar .cb-enable:hover {background:#009900;}
#cookie-bar .cb-disable {background:#990000;}
#cookie-bar .cb-disable:hover {background:#bb0000;}
#cookie-bar .cb-policy {background:#0033bb;}
#cookie-bar .cb-policy:hover {background:#0055dd;}