/* Custom CSS */
html, body {
    overflow-x: hidden;
}

a {
    color: #333;
    text-decoration: none;
}

    a:hover {
        color: #989c9f;
    }

        a:hover > svg {
            color: #989c9f;
        }

input:not([type='checkbox']), select {
    border-radius: 0;
    -webkit-appearance: none;
}

.bag-opener-smallnav:hover .fa-shopping-bag {
    color: #989c9f;
}

.bag-opener-smallnav .account-nav-link .fa-user {
    margin-bottom: 3px;
}

/* Column for 5-column layout, i.e. 20% width */
.w-col-20pc {
    width: 20%;
}

/* Platfrom CSS */
footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }


.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.recently-viewed-section {
    position: relative;
}

.header-container.w-container.remove-padding-top {
    padding-top: 0;
}

.horizontal-scroll-box {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    justify-content: flex-start;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    /*scroll-snap-type: x proximity;*/
    scrollbar-width: thin;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    /*scroll-snap-stop: normal;*/
}

.horizontal-scroll-item {
    flex: 2 0 auto;
    width: 20%;
    /*scroll-snap-align: start;
    scroll-snap-stop: normal;*/
}

.scroll-arrow-left,
.scroll-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 50%;
    cursor: pointer;
    overflow: hidden;
    margin-top: 90px;
    font-size: 40px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .scroll-arrow-left [class^="w-icon-"],
    .scroll-arrow-right [class^="w-icon-"],
    .scroll-arrow-left [class*=" w-icon-"],
    .scroll-arrow-right [class*=" w-icon-"] {
        position: absolute;
    }

.scroll-arrow-left {
    z-index: 3;
    right: auto;
    left: -25px;
}

.scroll-arrow-right {
    z-index: 4;
    left: auto;
    right: -45px;
}

.the-website {
    padding-top: 122px;
}

    .the-website.pf-promotion-strip-enabled {
        padding-top: 162px;
    }

.pf-promotion-container {
    text-transform: initial;
    font-size: 17px;
    color: #000;
    letter-spacing: 1px;
    border-top: 1px solid #fff;
    padding: 0 10px;
}

.pf-promotion-container a{
    text-decoration: none;
}

.logo-section {
    position: static;
    border-bottom: none;
}

.nav-section,
.nav-section.white-line {
    position: static;
    top: unset;
}

.pf-nav-wrapper {
    padding-bottom: 20px;
}

#sidenav-close-btn {
    color: #fff;
    font-size: 35px;
    left: auto !important;
    right: 10px;
}

#sidenav div, #sidenav a {
    padding-top: 4px;
    padding-bottom: 4px;
}

#sidenav > div:not(#sidenav-close-btn), #sidenav > a {
    border-bottom: 1px solid white;
    background-color: #6d6d6b;
    font-size: 15px;
    font-weight: 100;
}

#sidenav > a {
    padding-top: 17px;
    padding-bottom: 17px;
}

#sidenav .nav-link:hover {
    background-color: #6d6d6b;
}


#sidenav .pf-nav-images {
    display: none;
}

.w-container {
    max-width: 1250px;
    padding: 0;
}

div.w-container {
    max-width: 1250px;
}

._1100w-div-block {
    max-width: 1100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.input-wrapper .login-btn {
    height: 40px;
}

.access-code-input{
    width:285px;
}

.central-align-content-wrapper{
    display:flex;
    justify-content: center;
}

footer h3, .banner-usp h3 {
    color: white;
}

#pf-product-listing {
    padding-left: 6px;
    padding-right: 6px;
}

.w-row.product-row-wrapper > .w-col {
    padding-left: 0;
    padding-right: 0;
}

.filter-select-wrapper > .w-col, #pf-product-listing .w-col {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.header-width-70 {
    width:70%;
}
a.pf-cart-remove-btn.pf-normal-link {
    position: relative;
    display: block;
    color: #989c9f;
    font-size: 12px;
    font-weight: 400;
    text-decoration: none;
}
.pf-checkout-cart-table .qtywrapper.pf-qty-wrapper {
    margin-top:20px;
}
.lobibox-notify-warning .lobibox-notify-msg {
    color:#000;
}
.barclays-logo {
    margin-bottom:25px;
}
.barclays-button {
    background-color: #00afe9;
    width:200px;
}
.logged-in-barclays-input {
    width:200px;
}
#payment-failed-page {
    margin-bottom:80px;
}
#barclays-fail-msg {
    margin-top:20px;
}
.pf-sort-by-options {
    display: flex;
    float: right;
}
.pf-sort-by-options label {
    float: left;
    font-weight: 400;
    margin-top: 8px;
    font-size: 18px;
    min-width: 60px;
}
.pf-sort-by-options select {
    min-width: 200px;
    float: left;
    width: auto;
    height: 38px;
    margin: 0 7px;
}
.embedsocial-hashtag {
    width:100%;
}
.wishlist-sorting-container .w-col {
    padding:3px;
}
.back-in-stock-badge {
    line-height: 16px!important;
    padding: 2px 1px !important;
}
.events-heading {
    margin-bottom:0;
}
.events-form {
    margin-top: 40px;
    margin-bottom: 40px;
    width:50%!important;
}
@media (max-width: 991px) {
    .events-form {
      width:100%!important;
    }
    div #paymentMethods > .w-col {
        width: 100%;
        margin-bottom: -20px;
    }

    .the-website.pf-promotion-strip-enabled {
        padding-top: 142px;
    }

    .the-website {
        padding-top: 107px;
    }



    .w-container {
        padding: 0px 10px;
        max-width: 728px;
    }

    .horizontal-scroll-item {
        width: 25%;
    }
}

@media (max-width: 767px) {
    .back-in-stock-badge {
        line-height: 12px !important;
        font-size:10px!important;
        letter-spacing:3px!important;
        max-width:60px!important;
    }
    .cart-table img {
        height: 105px;
    }
    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .mobile-cart-price {
        display:none;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }
    .home-main-section .colour-section.collectionItems .mbss-listItem2-wrapper, 
    .home-main-section .colour-section.collectionItemsLoggedOut .mbss-listItem2-wrapper {
        grid-template-columns: repeat(1,1fr) !important;
    }
}

@media (max-width: 769px) {

    .horizontal-scroll-item {
        width: 33%;
    }

    h2 {
        font-size: 24px;
        line-height: 32px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .bespoketab img {
        height: 470px;
        width: 100%;
    }

    .full-width-inner-container.pt-b20.gal .img-overlay {
        height: 375px;
    }
}


@media screen and (max-width: 630px) {
    .horizontal-scroll-item {
        width: 50%;
    }
}


@media screen and (max-width: 479px) {
    .pagination {
        padding: 0px 10px;
    }

    .horizontal-scroll-item {
        width: 100%;
    }

    .brand {
        max-width: 150px;
    }
}

/*/
  /*New*/
.pf-page-wrapper {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 60px;
    background-color: #fff;
}

.pf-btn.pf-btn-default.pf-button-with-input {
    padding: 8px 10px !important;
    top: -3px;
}

#pf-product-details-page .pf-product-image-thumb {
    padding: 20px 0px !important;
    background: white;
    width: unset;
}

#pf-product-details-page .pf-qty-select {
    width: 30px;
}

#pf-product-details-page .pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
    min-width: 169px !important;
}

footer ul li {
    list-style: none;
}

.account-row {
    margin-bottom: 30px;
}

span.w-button.pageno {
    background-color: transparent;
    color: #444;
}

.brand-filter {
    display: none;
}

html, body {
    overflow-x: hidden;
}
/* Platfrom CSS */
.w-list-unstyled ul li {
    margin-left: 0;
    list-style: none !important;
}

.brand-filter {
    display: none;
}

footer .w-button, footer .button {
    color: hsla(0, 0%, 100%, .6) !important;
    border-color: hsla(0, 0%, 100%, .6) !important;
    background-color: transparent !important;
}

    footer .w-button:hover, footer .button:hover {
        color: #666 !important;
        background-color: white !important;
    }

.blogpost-caption div, .blogpost-hover div, .blogpost-text div {
    display: inline-block;
}

.blogpost-caption h1, .blogpost-caption h2, .blogpost-caption h3, .blogpost-caption h4 {
    margin-top: 0px;
    margin-bottom: 7px;
}

.blog-layout-full-width-featured-posts h2 {
    font-size: 14px !important; /*Doesn't overwrite blog-core.css without important*/
}

.newsletter-section h3 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.w-container {
    max-width: 1250px;
    padding: 0px 0px;
}

.input-wrapper .login-btn {
    height: 40px;
}

footer h3, .banner-usp h3 {
    color: white;
}


@media (max-width: 991px) {

    div#paymentMethods > .w-col {
        width: 100%;
        margin-bottom: -20px;
    }

    .w-container {
        padding: 0px 10px;
    }
}

@media (max-width: 767px) {
    .pf-page-wrapper {
        overflow: hidden;
        padding: 50px 10px;
    }

    h1.productListingMainHeading, .breadcrumb {
        padding-left: 10px;
    }

    .product-detail-slider-arrow {
        color: #ddd;
    }

        .product-detail-slider-arrow:hover {
            color: #666;
        }

    .qtybasket.space {
        text-align: left;
    }

    .m-filter-wrapper {
        display: none;
        opacity: 0;
    }

    td.visible-xs.text-right {
        width: 13%;
    }

    .hidden-xs {
        display: none;
    }

    .visible-xs {
        display: table-cell;
    }

    .product-detail-price {
        display: block;
        margin-top: 20px;
    }

    .variant-table {
        margin-bottom: 30px;
    }

    .product-detail-bottom-tabs {
        margin-top: 30px;
    }

    .product-detail-slider {
        display: block;
        height: auto;
        background-color: transparent;
        display: block;
        opacity: 1;
        margin-bottom: 30px;
        margin-top: 20px;
    }

    .filter-heading.mobile {
        display: block;
    }

    .w-row.hide {
        display: none;
        background-color: #fff;
    }

    .tab, .tab.w--current {
        width: 100%;
    }

    .tabs {
        display: none;
    }

    .breadcrumb {
        margin-top: 0px;
    }

    .tabcontent {
        border: none;
        padding-left: 10px;
        padding-right: 10px;
    }

    #node, #product-sort {
        margin-top: 0px;
    }

    .w-form.results-filter-wrapper {
        display: none;
    }

    .tabmenu {
        margin-bottom: 0px;
    }
}


@media screen and (max-width: 479px) {
    .pagination {
        padding: 0px 10px;
    }
}

.button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    margin-top: 10px;
    padding-right: 30px;
    padding-left: 30px;
    background-color: #2d7648;
    letter-spacing: 0;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 28px;
    color: #fff;
    border: 0;
    line-height: inherit;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
}

    .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #3c3c3a;
    }

/*grey button*/
.banner-container.grey a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .greybutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid #666;
    background-color: transparent;
    -webkit-transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    transition: color 500ms ease, background-color 500ms ease, border 600ms ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-shadow: none;
    text-decoration: none;
}

    .banner-container.grey a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover, .greybutton-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        color: white;
        background-color: #666;
    }
/*contact us*/
.mbss-emailForm-wrapper input:hover {
    border-color: #666;
}

.contactaboutbanner {
    background-size: cover;
    background-position: 50% 50%;
}

.about-icon {
    margin-bottom: 15px;
}


/*Blas fixes*/
#myCarousel-1-1 .mbss-editmode {
    position: absolute;
}

#myCarousel-1-1 ul.mbss-textArea-btns {
    top: 70%;
    left: 6%;
}

#myCarousel-1-1 ul.redactor-toolbar {
    left: 3% !important;
}

.project-column {
    margin-bottom: 25px;
}

.footerelementwrapper p {
    margin-bottom: 0px;
}

.gridcat {
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.product-info-match-height {
    margin-top: 12px;
}

.section.products, .section.top-categories {
    padding-top: 40px;
}

.lg-backdrop.in {
    z-index: 9999999 !important;
}

.lg-outer.lg-visible {
    z-index: 9999999 !important;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

    .slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.flex-child a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 20px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 12px 27px;
    border: 1px solid #fff;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .5s ease;
    transition: color .5s ease,background-color .5s ease,border .5s ease;
    color: #fff;
    font-size: 13px;
    line-height: 21px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
}

    .flex-child a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        border-color: transparent;
        background-color: #fff;
        color: #666;
        text-decoration: none;
    }

.ui-autocomplete {
    z-index: 99999 !important;
}

.list-view-add-cart-wrapper .button {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    letter-spacing: 1px;
    text-shadow: none;
    border: 1px solid #666;
    text-transform: uppercase;
}

    .list-view-add-cart-wrapper .button:hover {
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.social-icon-wrapper {
    margin-top: 15px;
}

.createaccount {
    margin-top: 50px;
}

    .createaccount .w-container {
        margin-bottom: 50px;
    }

.productcontent {
    cursor: zoom-in;
}

.brandsection .button-wrapper a:hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-shadow: none;
}

#emailForm-31-6 {
    margin-top: 18px
}

    #emailForm-31-6 .textarea {
        min-height: 134px;
    }

    #emailForm-31-6 .green-btn {
        float: right;
    }


.mega-nav-nav{
    padding-top: 0;
}

.mega-nav-images, .mega-nav-header {
    padding-top: 20px;
    margin: 0 -1px;
}

.headersAndListItemsHeader {
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    font-weight: bold;
}

.headersAndListItemsP {
    text-align: center;
    max-width: 1100px;
    margin: auto;
}
/*End blas*/
.img-overlay {
    display: block !important;
    background-size: cover;
    background-position: 50% 50% !important;
}

.mbss-emailForm-wrapper input:focus {
    border-color: #666;
}

.section.top-categories {
    padding-bottom: 0px;
}
/*IE Explorer Fix*/
.banner-container.w-container {
    max-width: inherit;
}

/*blog*/
.blog-details img {
    margin-bottom: 15px;
}

@media screen and (min-width: 1200px) {
    .blog-img-large {
        min-height: 786px !important;
    }
}
/*product pages tidy up*/
h1 {
    font-family: 'Open Sans',sans-serif;
}

.pf-product-list-page, #pf-product-details-page, #pf-product-listing {
    padding-bottom: 20px;
    margin-bottom: 0;
    margin-top:5px;
}
.pf-promotion-strip-enabled .pf-product-list-page, .pf-promotion-strip-enabled #pf-product-details-page, .pf-promotion-strip-enabled #pf-product-listing {
    margin-top: 15px;
}

#pf-product-details-page ._1100w-div-block {
    padding-top: 40px;
}

.pf-breadcrumbs .pf-normal-link, .breadcrumb .pf-normal-link {
    text-decoration: none;
}

.productListingMainHeading {
    margin-top: 10px;
    margin-bottom: 20px;
}

.pf-brand-info {
    display: none;
}

/*Gallery*/
#grid {
    margin-bottom: 100px;
}

.grid-text-center {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    text-align: center;
}

.grid-sizer {
    width: 25%;
    float: left;
}

.grid-item {
    position: relative;
    overflow: hidden;
    width: 31.33%;
    height: 240px;
    margin-right: 1%;
    margin-bottom: 2%;
    margin-left: 1%;
    float: left;
}

    .grid-item.grid-item--height {
        height: 240px;
    }

    .grid-item.grid-item-grid-item--height2 {
        height: 340px;
    }

    .grid-item.grid-item-grid-item--height3 {
        height: 280px;
    }

.grid-image {
    position: relative;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
}

.grid-text {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    color: #fff;
}

.grid-text-center {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    padding-right: 20px;
    padding-left: 20px;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
    text-align: center;
}

.grid-text {
    width: 100%;
    height: 0px;
    padding-top: 0px;
    overflow: hidden;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
    margin-top: 300px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    color: #FFF;
    font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
}

.grid-wrapper {
    display: block;
    max-width: 940px;
    margin-right: auto;
    margin-left: auto;
}

.grid-text {
    height: 0px;
}

.grid-item:hover .grid-text {
    display: block;
    margin-top: 0px;
    height: 100%;
}

.grid-item:nth-child(2n+1) {
    height: 375px;
}

.grid-image:nth-child(2n+1) {
    height: 375px !important;
}

.grid-item:nth-child(3n+2) {
    height: 300px;
}

.grid-image:nth-child(3n+2) {
    height: 300px !important;
}

.grid-wrapper .addListItem2Btn {
    right: -80px !important;
}

.grid-wrapper .deleteListItem2Btn {
    bottom: 0px !important;
}

.grid-text-center .re-table {
    display: none !important;
}

.grid-text-center .re-fontcolor {
    display: none !important;
}

.grid-text-center .re-table {
    display: none !important;
}

.grid-text-center .re-lists {
    display: none !important;
}

.grid-text-center .re-deleted {
    display: none !important;
}

@media (max-width: 992px) {
    .grid-item {
        width: 48%;
    }

    .hidemobile {
        display: none;
    }

    .about-content-wrapper {
        display: block;
        max-width: 100%;
        margin: 20px auto;
    }

    .center-flex {
        position: absolute !important;
    }
}

@media (max-width: 479px) {
    .grid-item {
        width: 100%;
        margin-right: 0%;
        margin-bottom: 15px;
        margin-left: 0%;
    }
}
/*End Gallery*/
.title-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 26px;
    border: 1px solid #666;
    background-color: transparent;
    -webkit-transition: color .5s ease,background-color .5s ease,border .6s ease;
    transition: color .5s ease,background-color .5s ease,border .6s ease;
    color: #666;
    font-size: 12px;
    line-height: 21px;
    text-shadow: none;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1px;
}

    .title-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
        background-color: #666;
        color: #fff;
        text-decoration: none;
    }

.section.cta-bg .redactor-toolbar-box, .section.cta-bg .mbss-textArea-btns {
    left: 50px;
}

.legal-info {
    float: right;
}

.section.cta-bg .editImageX {
    top: 0 !important;
    left: 100px !important;
}

.top-right .nav-link {
    text-transform: none !important;
}

.info-wrapper-right {
    text-align: center;
}

.brandsection .button-wrapper a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-shadow: none;
}

.sub-cat-holder-match-height .m-product-name {
    margin-top: 5px;
}

.col-subcat {
    padding-right: 5px !important;
    padding-left: 5px !important;
}

.slider-container a:not(.mbss-btn-icon):not(.re-icon):not(.re-button):hover {
    border-color: transparent;
    background-color: #fff;
    color: #666;
    text-decoration: none;
}

.blog-layout-full-width-featured-posts .blogpost-category-link:hover {
    background-color: #666 !important;
    color: #fff !important;
    text-decoration: none !important;
}

#pf-product-details-page .pf-product-image-thumb {
    padding: 0px !important;
}

    #pf-product-details-page .pf-product-image-thumb img {
        width: 100%;
    }

#pf-product-list-page .breadcrumb {
    margin-bottom: 0px !important;
}

#pf-product-list-page .section {
    padding-top: 5px;
}

.category-header {
    text-align: center;
}

.category-heading {
    display: inline-block;
    padding: 0 10%;
    margin-top: 20px;
    border-bottom: 1px solid #000;
}

.category-description {
    width: 100%;
    margin-bottom: 20px;
}

.product-link-block,
.product-link-block:active,
.product-link-block:visited {
    color: #000;
}

.filter-subdropdown-link.pf-filter-item {
    padding: 0px 10px;
}

.productwrapper {
    padding: 0;
    margin: 20px 0;
    min-height:270px;
}

.product-code {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (max-width: 991px) {
    .legal-info {
        width: 100%;
    }

    .bottom-footer-row {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .bottom-footer-row {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
    }

    .wishlist-price-header, .wishlist-total-header {
        display: table-cell !important;
    }
}
/*Modal*/
.modal-background {
    position: fixed;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 9000;
    display: none;
    width: 100%;
    height: 100%;
    padding: 30px 20px;
    background-color: rgba(0, 0, 0, .8);
    /*opacity: 0;*/
    color: #fff;
    font-weight: 300;
}

.close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 8px;
    font-family: 'Corin design', sans-serif;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    text-align: center;
    text-decoration: none;
}

.modal-body {
    position: absolute;
    left: 0px;
    top: 50%;
    right: 0px;
    display: block;
    width: 100%;
    max-width: 960px;
    margin-right: auto;
    margin-left: auto;
    -webkit-transform: translate(0px, -50%);
    -ms-transform: translate(0px, -50%);
    transform: translate(0px, -50%);
}

.section-title-block {
    margin-bottom: 50px;
    padding-right: 150px;
    padding-left: 150px;
    clear: both;
    text-align: center;
}

.modal-element-wrapper {
    position: relative;
    display: block;
    overflow: hidden;
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
}

.modal-form-wrapper {
    display: block;
    margin-top: 20px;
    margin-right: auto;
    margin-left: auto;
    float: none;
}

.modal-form {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.modal-background .close-btn {
    top: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    padding-top: 8px;
    color: #fff;
    font-size: 21px;
    line-height: 32px;
    z-index: 10;
    text-decoration: none;
}

@media (max-width: 767px) {
    .modal-body {
        position: relative;
        top: 0%;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
    }

    .modal-element-wrapper {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
}

.section.tint.bespoke {
    padding-bottom: 50px;
    font-size: 19px;
    line-height: 27px;
}

.full-width-container {
    padding-right: 50px;
    padding-left: 50px;
}

.full-width-inner-container, .title-wrapper-copy {
    padding-right: 10px;
    padding-left: 10px;
}

.w-richtext ul {
    padding: 0px;
}

.w-richtext li {
    font-weight: 300;
}

.text-align-center .button p {
    margin-bottom: 0px !important;
}

.text-align-center .button a:hover {
    color: white;
}

.title-wrapper-copy {
    position: relative;
    z-index: 10;
    margin-bottom: 30px;
    font-size: 19px;
    line-height: 27px;
}

.bespoke-img {
    margin-bottom: 40px;
    width: 100%;
}
    /*.bespoke-img a{
      width: 100%;
}*/
    .bespoke-img .mbss-image-scale {
        width: 100%;
    }

@media (max-width: 479px) {
    .full-width-container {
        padding-right: 10px;
        padding-left: 10px;
    }

    .pf-addresses-wrapper .w-col-6 {
        width: 100%;
    }
}

.enquire-btn.contact.w-button {
    background-color: white;
}

    .enquire-btn.contact.w-button:hover {
        background-color: #666;
    }

.about-content-wrapper .blogpost-caption ul.redactor-toolbar {
    position: relative !important;
}

.legal-info .mbss-text ul.redactor-toolbar {
    left: -20px !important;
}

.grid .title-wrapper {
    margin-bottom: 15px;
    margin-top: 60px;
}

.m-filter-wrapper {
    opacity: unset;
    display: block;
}

.pf-product-images-slider-arrow {
    background-color: transparent;
    opacity: unset;
}

.trade-account-header {
    width: 70%;
    display: inline-block;
}

.button-trade-application {
    width: 200px;
    background-color: #2d7648;
    text-align: center;
}
.button-trade-application:hover {
    background-color: #3c3c3a;
}
.button-trade-application a {
    color: #fff;
}
.button-trade-application.top-button-application{
    float:right;
}
.button-trade-application.previous-page {
    background-color: #3c3c3a;
}
.button-trade-application.previous-page:hover {
    background-color: #000;
}
.button-trade-application.previous-page.bottom-submit {
    margin-top:10px;
}

@media (max-width: 767px) {
    .breadcrumb {
        padding-left: 0px;
    }

    #pf-product-list-page .product-list-header-text.p, #pf-product-list-page .breadcrumb {
        padding-left: 10px;
    }

    .pf-tab-header {
        width: 100% !important;
        text-align: center;
    }

    .center-flex._3, .center-flex._4 {
        top: unset;
    }
}

.banner {
    height: 500px;
}

.slider-container {
    position: absolute !important;
}

    .slider-container ul.mbss-textArea-btns {
        left: 20% !important;
        top: -40px !important;
    }

.m-filter-wrapper {
    opacity: 1;
}

.nav-hamburger-wrapper .search-opener, .nav-hamburger-wrapper .bag-opener-smallnav, .nav-hamburger-wrapper .quote-opener {
    display: none;
}
@media (max-width: 992px) {
    .mapiframe iframe {
        width: 100%;
    }
    .header.shrink .nav-hamburger-wrapper .search-opener, .header.shrink .nav-hamburger-wrapper .bag-opener-smallnav  {
        display: inline-block;
    }
    .header.shrink .nav-hamburger-wrapper .quote-opener, .header.shrink {
        display: inline-block;
    }
}

.contactv2 {
    height: 725px;
    background-position: 50% 33%;
}

@media (max-width: 992px) {
    .contactv2 {
        height: 330px;
    }
}

.about-content-wrapper img {
    height: 100%;
}

.filter-cats-sub .subdropdown-toggle.pf-filter-item {
    padding: 5px 10px;
}

.filter-subdropdown-link.pf-filter-item {
    padding: 5px 10px;
}

@media (max-width: 547px) {
    #product-sort {
        margin-left: 5px;
    }
}

#cookie-bar {
    z-index: 999;
}

.contactv3 {
    height: 475px;
    background-position: 50% 50%;
}

    .contactv3 .banner-container h3, .contactv3 .banner-container h2, .contactv3 .banner-container h1, .contactv3 .banner-container h4 {
        text-transform: none !important;
    }

.grid-text .redactor-placeholder:after {
    color: white;
}

/*Fix on hover transition for products*/
.productwrapper:hover {
    box-shadow: 0 6px 20px 4px rgba(26,26,26,.25);
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #666;
    text-decoration: none;
}

.div-block-7 h1, .div-block-7 h2, .div-block-7 h3 {
    margin-bottom: 0;
    padding: 10px 26px;
    border: 1px solid #666;
    font-size: 16px;
    line-height: 25px;
    text-transform: uppercase;
    margin-top: 0;
    font-weight: 400;
}

.step-title-container h2, .step-title-container h3 {
    padding-top: 5px;
    padding-bottom: 5px;
    border-bottom: 2px none #e0e0e0;
    color: #fff;
    font-size: 26px;
    line-height: 30px;
    margin-top: 10px;
    font-weight: 400;
}

.pt-b20.gal img {
    height: 100%;
}

.bespoke-image-main {
    width: 100%;
}

.bespoketab img {
    height: 600px;
    width: 100%;
}

@media (max-width: 767px) {
    .pt-b20.gal .image-drop, .pt-b20.gal img {
        width: 100%;
    }

    .bespoke-image-main {
        min-height: 480px;
        margin-bottom: 20px;
    }

    .bespoketab img {
        height: 480px;
    }

    .div-block-7 {
        bottom: 15px;
    }

    #edit-details-btn, #save-address-btn {
        margin-left: 10px;
    }
}

@media (max-width: 770px) {
    .brandsection, .cta-bg {
        background-attachment: scroll;
    }

    .full-width-inner-container.pt-b20.gal .img-overlay {
        margin-bottom: 20px;
    }

    .full-width-inner-container.pt-b20.gal .separator {
        display: none;
    }

    .full-width-inner-container.pt-b20 .w-col-medium-3 p {
        margin-bottom: 0px;
    }
}

@media (max-width: 430px) {
    .pt-b20.gal .image-drop {
        height: auto;
    }

    .bespoke-image-main {
        min-height: 300px;
    }

    .bespoketab img {
        height: 330px;
    }

    .div-block-7 {
        width: auto;
    }

    .contactv3 .spectralsc h1 {
        font-size: 25px;
        line-height: 33px;
    }
}

.bespoketab {
    position: relative;
}

.div-block-7 {
    z-index: 99;
}

.banner-container-right {
    position: absolute;
    right: 32px;
    bottom: 0;
    z-index: 10;
    padding: .5% .5% 0;
    background-color: rgba(0,0,0,.3);
    transform: translate(0,-50%);
    color: #fff;
    font-size: 19px;
    line-height: 27px;
    text-shadow: 1px 1px 6px rgba(0,0,0,.66);
    min-width: 360px;
}

.heading-5.spectralsc h1 {
    font-family: 'Spectral SC',sans-serif;
}

.heading-5 h1 {
    font-weight: 200;
    text-transform: none;
    text-shadow: 2px 2px 6px rgba(0,0,0,.66);
}

.justify p {
    text-align: justify;
    text-align-last: center;
    -moz-text-align-last: center;
}

.text-block-14, .text-span-2 {
    display: inline-block;
}

.text-block-14 {
    margin-bottom: 0px;
}

@media (max-width: 457px) {
    .banner-container-right {
        right: 0px;
    }

    .signup-terms-cbx-wrapper .checkbox-label.w-form-label {
        padding-left: 5px;
    }
}

.blog-details .blog-img {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.new-left-bottom {
    font-size: 12px;
}

.lg-backdrop.in {
    opacity: 0.7 !important;
}

.broadloomGallery .lg-toolbar.group {
    display: none;
}

.broadloomGallery .lg-sub-html {
    padding: 34px 40px;
    font-size: 20px;
}

@media (max-width: 560px) {
    .broadloomGallery .lg-toolbar.group {
        display: block;
    }

    .justify p {
        text-align: center;
    }

    .contactv3 .banner-container-right, .contactv2 .banner-container-right {
        width: 100%;
        right: 0;
        min-width: 0px;
    }
}

#ui-id-1 {
    background-color: #ddd;
    background: #ddd;
}

.grid-item .list-item2-drop {
    position: relative;
}

.grid-item .grid-text {
    z-index: auto;
}

.grid-item .galleryLink {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.grid-item .grid-text-center {
    z-index: 999;
}

.grid-text-center a {
    color: #c3bbbb;
    text-decoration: underline;
}

.grid-text {
    background-color: rgba(0,0,0,.6);
}

.pf-recent-related-product-item .product-image {
    min-width: unset;
    /*width: 100%;*/
}

input::-ms-clear {
    display: none;
}
#returnEditArea .postcodeClass, .quantitylabel {
    display: inline-block;
}

.quantitylabel {
    width: 19%;
}

.postcodeClass {
    width: 80%;
    float: right;
}
@media (max-width: 1024px) {
    .postcodeClass {
        width: 70%;
    }
    .grid-item .grid-text {
        top: unset;
        margin-top: 0px;
        min-height: 61px;
        /*margin-top:225px;
    min-height:170px;*/
    }

    .pf-detail-view-add-cart-wrapper {
        display: flex;
    }

    .grid-item .grid-text-center {
        min-height: 50px;
    }

    .grid-text-center p {
        margin-bottom: 5px;
    }

    .broadloomGallery .lg-toolbar.group {
        display: block;
    }
}

@media (max-width: 425px) {

    #pf-gallery-image-products .w-col-tiny-6 {
        width: 100%;
    }
}

#pf-product-listing .pf-product-list-options {
    display: none;
}

.pf-product-list-options select {
    min-width: 200px;
}

@media (min-width: 770px) {
    #pf-product-listing .results-filter-wrapper.pf-product-list-options {
        display: block;
    }
}

.pf-product-main-image-wrapper {
    text-align: center;
}
/*CoachHouse Blas's code*/
.account-div-block {
    color: #fff;
    max-height: 21px;
}

img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    max-height: 100%;
}

.w-col.w-col-3.w-col-stack.pf-nav-images {
    display: flex;
    flex-wrap: nowrap;
}

.pf-nav-image {
    display: block;
    margin-top: 0 !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom:17px;
}

.pf-nav-image span {
    font-weight: bold;
    text-align: center;
    padding-top: 5px;
    min-height:25px;
}

.pf-nav-image:hover a+span {
    color: #989c9f;
}

.home-main-section,
.pf-page-wrapper,
.home-main-section.style,
.home-main-section.accounts,
.shopping-section {
    padding-top: 0;
}

.about-section {
    padding-top: 40px;
}

body, html {
    overflow-x: visible;
}

.header-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.header-container.header-container-padding-top-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.blog-image-400 {
    width: 400px;
    height: 400px;
}

.header-container h1, .header-container h2 {
    width: auto;
    margin-bottom: 20px;
    font-family: 'Perpetua Normal',sans-serif;
    font-weight: 400;
    text-align: center;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.header-container h1 {
    font-size: 20px;
}

.header-container h2 {
    font-size: 20px;
}

.about-div-block h2 {
    margin-top: 0;
    font-family: Arial,'Helvetica Neue',Helvetica,sans-serif;
    font-size: 14px;
    margin-bottom: 0;
    color: #000;
    letter-spacing: 2px;
}

.about-div-block {
    position: absolute;
    z-index: 1;
    height: 50px;
    bottom: 40px;
    left: 10px;
    right: 10px;
    margin-top: 0;
}

.slider {
    width: 100%;
}

._1250w-div.with-slider {
    max-width: 1230px;
    padding-bottom: 30px;
}

.top-footer-icon-heading {
    text-align: left;
}

    .top-footer-icon-heading h4 {
        color: #fff;
        font-size: 14px;
        letter-spacing: 1px;
    }

.footer-link a {
    color: #fff;
    font-size: 12px;
    text-decoration: none;
}

._1250w-div.main-footer p {
    margin-bottom: 0px;
}

._1250w-div.main-footer {
    display: block;
}

.title-section {
    margin: auto;
}

.videoWrapper:not(.redactor-in) {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
}

    .videoWrapper:not(.redactor-in) iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

.header-section:not(.header-not-width-40) {
    width: 40%;
    text-align: center;
}



.header-section.header-no-padding {
    padding: 0;
}

.about-col {
    margin-bottom: 25px
}

.secondary-page-top-container:not(.about-us-center):not(.inspiration) {
    max-width: 940px !important;
    margin-top: 0;
    margin-bottom: 0;
}

.secondary-page-top-container.carbon {
    margin-top: 40px;
}

.home-main-section .secondary-page-top-container.w-container {
    margin-bottom: 40px;
}


.about-us-center {
    margin-top: 0;
}

    .about-us-center .header-section {
        width: 30%;
    }

        .about-us-center .header-section h1 {
            margin-top: 0;
        }

.recently-viewed-section {
    border: none;
}

.fairs-and-events-section,
.testimonial-section {
    width: 100%;
}

.page-nav-header {
    margin-top: 0px;
    margin-bottom: 20px;
    color: #3c3c3a;
    font-size: 16px;
    text-transform: uppercase;
}

.secondary-page-top-container h1 {
    border-bottom: 1px solid #000;
    margin-bottom: 20px;
    font-family: 'Perpetua Normal',sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.secondary-nav a {
    color: #3c3c3a;
    margin-bottom: 5px;
    text-decoration: none;
    display: inline-block;
}

    .secondary-nav a:hover {
        color: #989c9f;
    }

.event-name, .testimonial-header, .page-nav-header {
    font-weight: bold;
}

.fairs-and-events-section h1 {
    width: auto;
    margin-bottom: 20px;
    font-family: 'Perpetua Normal', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 6px;
    text-transform: uppercase;
}

.event-post-div p {
    margin-bottom: 0px;
}

.terms.secondary-page-top-container {
    display: block;
}

.header-section.contact h1 {
    margin-top: 0px;
    font-family: 'Perpetua Light', sans-serif;
    font-weight: 400;
    letter-spacing: 4px;
    border-bottom: none;
    text-align: left;
}

.event-post-div {
    text-align: center;
}

.about-content-title .header-section {
    width: 100%;
}

.about-story-copy p, .dropdown-3 p {
    margin-bottom: 0px;
}

.dropdown-3 {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    border-style: none none solid;
    border-width: 1px;
    border-color: #989c9f;
    color: #3c3c3a;
    text-align: left;
    text-decoration: none;
    padding-top: 5px !important;
}

.question-link {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-left: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
}

.faq-dropdown-list.w--open {
    position: relative;
    height: auto;
    padding: 20px 10px 10px;
    background-color: transparent;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    color: #222;
    font-weight: 300;
    margin-top: 0px;
}

.faq-dropdown {
    width: 100%;
    padding: 0;
}

.faq-dropdown-list {
    border-bottom: 0;
}

.mbss-text.faq-dropdown-list ul.redactor-toolbar {
    top: -58px !important;
}

.mbss-text.question-link ul.mbss-textArea-btns, .mbss-text.faq-dropdown-list ul.mbss-textArea-btns {
    left: 410px;
    top: -38px;
}

.downloads-dropdown .mbss-text.faq-dropdown-list span.re-button-tooltip {
    top: 0 !important;
}

.downloads-dropdown .mbss-text.faq-dropdown-list ul.redactor-toolbar {
    top: -38px !important;
}

.downloads-dropdown .mbss-text.faq-dropdown-list ul.mbss-textArea-btns {
    width: 78px;
}

.blog-header h1 {
    margin-bottom: 20px;
    font-family: 'Perpetua Normal', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 6px;
    text-transform: uppercase;
    border-bottom: 1px solid #000;
    line-height: 44px;
    margin: 0;
    margin-bottom: 20px;
}

.w-section.section.blog-details.about-section {
    padding-top: 0;
}

.blog-layout-full-width-featured-posts h2.news-blog-title {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 0;
    text-align: center;
    font-weight: 500;
    letter-spacing: 2px;
}

.dropdown-faq:before {
    bottom: 5px;
    content: '\002B';
    font-size: 24px;
    font-family: Georgia, Times, 'Times New Roman', serif;
}

.faq-dropdown.w--open .dropdown-faq:before {
    content: '\2212';
}

.about-story-copy a:not(.mbss-btn-icon):not(.re-icon):not(.re-button), .catalogue-txt a:not(.mbss-btn-icon):not(.re-icon):not(.re-button) {
    margin-top: 10px;
    /*font-size: 16px; Not sure why this was added originally? - TS*/
    text-decoration: none;
}

.nav-section {
    position: relative;
}

.header {
    left: 0;
    top: 0;
    right: 0;
    z-index: 9000;
    box-shadow: 3px 3px 15px 0 rgba(0,0,0,.2);
    font-weight: 300;
    position: fixed;
    background-color: #fff;
}

.pf-load-more-container {
    flex-wrap: wrap;
    flex-direction: column;
}

#search-results-wrapper .pf-load-more-container {
    padding-top: 30px;
}

.you-have-viewed-label {
    font-weight: 400;
    color: #989c9f;
    padding-bottom: 10px;
    margin-left:auto;
    margin-right:auto;
}


.pf-load-more-btn {
    min-width: 183px;
    height: 40px;
    background-color: #3c3c3a;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    border: 0;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    margin-left:auto;
    margin-right:auto;
}

.hamburger-menu-text {
    font-size: 12px;
    vertical-align: middle;
    margin-right: 5px;
}

.hamburger-menu-icon {
    color: #fff;
    font-size: 30px;
    cursor: pointer;
}

.search-container {
    max-width: 940px;
    padding: 0 20px;
}

.search-close {
    color: #333;
}

.search-opener {
    color: #fff;
}

.search-section .nav-container {
    height: auto;
}

.search-section{
    margin-top: -80px;
}

#scrollUp {
    color: #333;
    background-color: transparent;
    position: fixed;
    left: auto;
    top: 50%;
    right: 5%;
    bottom: 0%;
    z-index: 999;
    height: 40px;
    font-size: 45px;
}

.back-to-top-link-block {
    color: #333;
}

.w-dropdown-list {
    width: 100%;
    margin-top: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    background-color: #fff;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.6);
}

.secondary-nav-col.w-col.w-col-3:not(.keep-width-25pc) {
    width: 21%;
}

.secondary-nav-col.w-col.w-col-3.about-us-center {
    z-index: 10;
    position: absolute;
}

.mega-menu-heading {
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 24px;
    font-weight: bold;
    text-decoration: none;
    color: #333;
    display: block;
}

.pf-btn.pf-btn-sm.pf-btn-primary {
    margin-top: 13px;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #3c3c3a;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 15px;
    color: #fff;
    border: 0;
    line-height: inherit;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
    font-size: 14px;
    height: auto;
    min-width:250px;
}

.pf-checkout-cart-table table.cart-table th {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: bold;
}

.pf-btn.pf-btn-sm.pf-btn-primary:hover {
    background-color: #000;
}

.shopping-section .price-col-2.shopping-basket {
    padding-bottom: 52px;
}

.pf-checkout-cart-table tr {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

    .pf-checkout-cart-table tr.header-table {
        border-top: none;
    }

.pf-checkout-cart-table td, .pf-checkout-cart-table {
    border: none;
}

.cart-container.w-container {
    max-width: 940px;
}

.pf-qty-select {
    border-left: none;
    border-right: none;
}

.pf-qty-btn {
    background-image: none;
    background-color: #fff;
}

.pf-checkout-cart-table .cart-table th {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 30px;
    font-weight: 700;
}

.price-header-left {
    float: left;
}

.recently-viewed-section.cart-section {
    padding-bottom: 0px;
}

.total-price-container .pf-quick-order-quick-entry .add-item-box {
    width: 200px;
}

.total-price-container .pf-quick-order-quick-entry .pf-input {
    display: inline-block;
}

.pf-wishlist-quick-entry-code {
    display: inline-block;
    max-width: 196px;
}

.pf-wishlist-quick-entry {
    width: 100%;
}

.pf-wishlist-cart-table td.favourites-hr {
    padding: 0 !important; /*needs important due to it being important in fixed pages*/
}

.pf-add-all-to-cart-btn {
    margin-right: 10px;
}

.pf-qty-select, .pf-qty-btn {
    height: 30px;
}

.total-price-container .pf-qty-wrapper {
    margin-right: 10px;
}

.total-price-container .pf-quick-add-to-cart-btn {
    border-radius: 0px;
    height: 30px;
    font-size: 14px;
}

    .total-price-container .pf-quick-add-to-cart-btn:hover, .pf-btn.pf-btn-colour-one:hover {
        background-color: #000;
    }

.shopping-section {
    padding-bottom: 0px;
}

.hide-checkout {
    display: none;
}

#makePaymentBtn {
    width: 200px;
}

.order-total-label, .order-total-value {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: right;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    padding: 5px 0;
}

.bag-opener span, #bag-opener, .bag-opener-smallnav {
    color: #fff;
    text-decoration: none;
}

.price-form.checkout {
    float: right;
    padding: 0 10px 0 0;
}

.po-number-text-field, .select-order-type-field {
    width: 100%;
}

.pf-checkout-actions, #makePaymentBtn {
    margin-top: 0px;
}

input.pf-hover-colour-one:hover, .pf-input.pf-hover-colour-two:focus, .pf-input.pf-hover-colour-two:hover, .pf-input.pf-hover-colour-one:focus, .pf-input.pf-hover-colour-one:hover {
    border-color: #ccc;
}

.recently-viewed-section {
    display: block;
}

._1250w-div {
    position: relative;
}

.bag-opener span {
    position: absolute;
    top: 14px;
}

.megamenu-col {
    display: none;
}

#main-nav .megamenu-col .pf-nav-wrapper .w-col div:not(:first-child) {
    margin-top: 40px;
}

.w-dropdown {
    position: static;
}

/* Make mega nav appear full-width with massive padding */
.megamenu-col.w--open {
    left: -1000px;
    right: -1000px;
    width: auto;
    padding-left: 1000px;
    padding-right: 1000px;
    top: 97%;
    margin-top: 0px;
}

.nav-link.nav-dropdown, .w-nav-link.nav-link.w--nav-link-open {
    margin-top: 9px;
    margin-bottom: 9px;
}

.nav-link.nav-dropdown, .w-nav-link.nav-link {
    text-transform: uppercase;
}

.mega-menu-link {
    display: block;
}

.pf-highlight-nav {
    color: #c9292b;
    text-transform: uppercase;
}

.pf-subhighlight-nav {
    color: #c9292b;
}

a:hover .pf-highlight-nav, a:hover .pf-subhighlight-nav, .pf-nav-image:hover a+span.pf-subhighlight-nav {
    color: #fda9b5;
}

.register-btn.w-button.request-new-password-btn {
    margin-top: 10px;
}

#filtersColourOnly .filter-colours {
    display: flex;
}

    #filtersColourOnly .filter-colours .pf-colour-item {
        padding: 0 !important;
        width: 30%;
        margin-right: 10px;
    }

#pf-product-listing .pf-product-list-options {
    display: flex;
    float: right;
}

.pf-list-item-img {
    height: auto;
}

.pf-product-list-item .pf-product-name {
    padding-bottom: 0px;
    color: #000;
    font-size: 12px;
    text-align: center;
    margin-top: 0px;
}

.pf-product-list-item .pf-product-code {
    margin-bottom: 0px;
    color: #000;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
    line-height: 20px;
    margin-top: 10px;
    font-weight: bold;
}

.pf-product-list-item .pf-stock-info.pf-block, .pf-recent-related-product-item .pf-stock-info.pf-block {
    color: #000;
    font-size: 12px;
    font-style: italic;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0px;
}

#pf-product-details-page .pf-recent-related-product-item .pf-stock-info.pf-block .pf-custom-stock-message {
    font-size: 12px;
}

.pf-product-list-item .pf-product-price {
    color: #000;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
}

.pf-product-list-item, .productwrapper:hover {
    box-shadow: none;
}

.pf-product-list-item-img-wrapper {
    position: relative;
    margin: 0 10px;
    max-width: 270px;
}

.list-view-add-cart-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0px;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

    .list-view-add-cart-wrapper .pf-qty-wrapper {
        display: none;
    }

.pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary {
    height: 30px;
    margin-top: 0px;
    padding: 6px 15px;
}

.pf-btn.pf-btn-sm.pf-btn-primary.pf-quick-add-to-wishlist-btn {
    margin-top: 0;
}

.add-to-wishlist-btn {
    margin-right:20px;
}

.pf-new-product-tag, .pf-sale-product-tag {
    position: absolute;
    top: 0px;
    left: 0px;
    width: unset;
    height: unset;
    border: 2px #fff;
    opacity: 1;
    color: #fff;
    font-size: 14px;
    line-height: 25px;
    border-radius: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    font-family: 'Perpetua Light', sans-serif;
    color: #fff;
    text-align: center;
    letter-spacing: 3px;
    margin-top: 1px;
    padding: 0px;
    display: flex;
    justify-content: center;
    background-color: #3c3c3a;
    max-width: 60px;
}

.pf-new-product-tag span, .pf-sale-product-tag span {
    padding-left:5px;
    padding-right:5px;
}
.new-right-top.pf-sale-product-tag.limited {
    max-width:80px;
}
.new-right-top.pf-sale-product-tag.limited span {
    padding-left: 8px;
}

.price-sale.m-sale-price, .recently-viewed-price.recently-viewed-sale-price {
    color: #c9292b;
}

.price-old.pf-price-old + span[itemprop="price"] {
    color: #c9292b;
}

.pf-new-product-tag span.mac-os, .pf-sale-product-tag span.mac-os {
    padding-top: 4px;
}

.new-right-bottom.pf-new-product-tag, .new-right-top.pf-new-product-tag {
    left: unset;
    right: 0;
}

.pf-new-product-tag.limited {
    max-width: 83px;
}

.pf-new-product-tag.discon {
    max-width: 80px;
}

#pf-product-actions-wrapper {
    padding: 0px;
    border: none;
    padding-top: 10px;
}

.pf-product-details-heading {
    color: #989c9f;
    font-size: 18px;
    margin-bottom: 0px;
    font-family: Arial,sans-serif;
    line-height: 20px;
    font-weight: normal;
    padding-bottom: 10px;
}

.pf-product-details-info-wrapper .pf-product-details-heading {
    font-weight: 700;
    color: #3c3c3a;
    font-size: 25px;
    line-height: 28px;
}

.pf-product-code {
    color: #3c3c3a;
    font-size: 18px;
    margin-bottom: 0px;
    font-family: Arial,sans-serif;
    line-height: 20px;
    font-weight: normal;
}

.pf-product-details-description p {
    color: #989c9f;
    font-size: 12px;
    line-height: 16px;
}

.pf-product-details-price-wrapper {
    margin-top: 25px;
    margin-bottom: 20px;
    color: #3c3c3a;
    font-size: 25px;
    font-weight: 700;
}

.product-view-specifications-header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
}

.pf-product-details-description, .pf-product-details-info-wrapper {
    margin-bottom: 0px;
}

#product-stock-info {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 5px;
}

#search-results-spinner {
    font-size: 30px;
}

.pf-custom-stock-message {
    color: #000;
}

#pf-product-details-page .pf-custom-stock-message, #pf-product-details-page .pf-stock-summary-backorder {
    font-size: 15px;
}

#pf-product-details-page .pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
    height: 40px;
    margin-left: 5px;
    padding: 10px 35px;
}

#pf-product-details-page .pf-qty-btn {
    width: 23px;
    height: 40px;
}

#pf-product-details-page .pf-qty-select {
    height: 40px;
}

.wish-list-parent {
    height: auto;
    display: inline-block;
    padding: 9px 24px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

    .wish-list-parent svg {
        font-size: 1.5em;
    }

#pf-product-details-page .pf-detail-view-add-cart-wrapper {
    margin-bottom: 10px;
}

.filter-colours input, .pf-hex-colour {
    border: none !important;
    width: 100%;
    height: 100%;
}

.pf-colour-item img {
    height: auto;
    max-height: 20px;
}

.fixed-size.lg-outer .lg-inner {
    background-color: #fff;
}

.fixed-size.lg-outer .lg-sub-html {
    position: absolute;
    text-align: left;
}

.fixed-size.lg-outer .lg-toolbar {
    background-color: transparent;
    height: 0;
}

    .fixed-size.lg-outer .lg-toolbar .lg-icon {
        color: #000;
    }

.fixed-size.lg-outer .lg-img-wrap {
    padding: 12px;
}

.fixed-size.lg-outer .lg-prev, .fixed-size.lg-outer .lg-next {
    background-color: #fff;
    color: #000;
}

.pf-recent-related-product-item a {
    line-height: 20px;
}

.product-code {
    font-weight: bold;
}

.faq-dropdown-list.w--open {
    box-shadow: none;
}

:after, :before {
    display: block;
}

.catalogue-div {
    position: relative;
}

.pf-quick-order-top .pf-input, .pf-quick-order-top .pf-btn, #pfQoSearchBtn {
    height: 30px;
}

.wishlist-main-buttons .create-wish-List-btn, .wishlist-main-buttons .create-wish-List-btn:hover {
    margin-top: 13px;
    background-color: #2d7648;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 9px 10px;
    color: #fff;
    border: 0;
    line-height: inherit;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
}

.create-wish-List-btn {
    margin: 10px;
}

.pf-add-all-wishlists-to-cart-btn {
    margin: 10px;
    margin-left: 0;
}
/*.wishlistCheckbox:checked {
    background: #FFFFFF;
}*/
@font-face {
    font-family: "FontAwesome";
    src: url("/Scripts/FontAwesome/webfonts/fa-light-300.woff2");
    font-display:swap;
}

input.wishlistCheckbox {
    display: none;
}
    /* to hide the checkbox itself */
    input.wishlistCheckbox + label:after {
        font-family: FontAwesome;
        display: inline-block;
        font-size: 20px;
    }

    input.wishlistCheckbox + label:after {
        content: "▢";
        color: white;
    }
    /* unchecked icon */
    /*input.wishlistCheckbox + label:after{
        letter-spacing: 10px;
    }*/
    /* space between checkbox and label */

    input.wishlistCheckbox:checked + label:after {
        content: "\f00c";
        color: green;
    }
/* checked icon */
/*input.wishlistCheckbox:checked + label:after{
        letter-spacing: 10px;
    }*/
/* allow space for check mark */

.slider-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 10;
    -webkit-transform: translate(0,-50%);
    -ms-transform: translate(0,-50%);
    transform: translate(0,-50%);
    color: #fff;
    text-align: center;
    text-shadow: 1px 1px 6px rgba(0,0,0,.5);
}

    .slider-container ul.mbss-textArea-btns {
        left: 10% !important;
        top: 34px !important;
    }

#myCarousel-1-1 ul.redactor-toolbar {
    left: 8% !important;
}

.mbss-editmode.about-div-block {
    position: absolute;
}

    .mbss-editmode.about-div-block ul.redactor-toolbar {
        top: -65px !important;
        left: -42px !important;
    }

.top-footer-icon-heading {
    color: #fff;
    font-size: 14px;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 24px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 10px;
}

.last-col ul.redactor-toolbar {
    left: -160px !important;
}

.search-container .prod-search-btn {
    cursor: pointer;
}

.wishlist-picture-img {
    height: auto;
}

.align-right {
    float: right;
}

.pf-spec-table tr td:nth-child(2) {
    padding-left: 5px;
}

.pf-spec-table table {
    width: auto;
    margin-bottom: 0px;
}

.pf-spec-table table, .pf-spec-table td {
    border: none;
}

.pf-spec-table td {
    color: #989c9f;
    font-size: 12px;
    line-height: 16px;
    padding: 0;
}

.pf-spec-table tr:nth-child(odd) {
    background-color: #fff !important;
}

.spec-dropdown, .downloads-dropdown {
    height: auto;
}

    .spec-dropdown .w-dropdown-toggle, .downloads-widget .w-icon-dropdown-toggle {
        bottom: 10px;
    }

    .spec-dropdown-list.w--open, .downloads-dropdown .w-dropdown-list.w--open {
        border-bottom: none;
        border-top: 1px solid #989c9f;
        margin-bottom: 0px;
    }

.catalogue-div.mbss-hover-highlight .deleteListItem2Btn {
    visibility: unset !important;
}

.checkout-quote label {
    text-align: end
}

#print-btn {
    cursor: pointer;
}

.reference-type.print {
    float: none;
    display: inline-block;
}

.cancel-edit-wishlist-btn {
    padding: 2px 15px !important;
    top: 0px;
}

.post-edit-wishlist-btn {
    background-color: #2d7648 !important;
}

.cancel-edit-wishlist-name-btn, .post-edit-wishlist-btn {
    padding: 2px 12px !important;
    top: 0px;
}

.cancel-edit-wishlist-name-btn {
    width: 38px;
}

.post-new-wishlist-btn {
    background-color: #2d7648 !important;
    padding: 7px 12px !important;
}

.pf-edit-wishlist, .pf-create-wish-list {
    height: 34px;
}

.company-details-section p.bold {
    font-weight: 700;
}

#save-details-btn {
    background-color: #2d7648 !important;
}

    #save-details-btn:hover {
        background-color: #3c3c3a !important;
    }

.pf-my-acc-info-wrapper .w-input, .pf-my-acc-info-wrapper .w-select, .pf-my-acc-pass-wrapper .w-input {
    height: 30px;
}

.pf-my-acc-info-wrapper .w-select {
    padding: 5px 12px;
}

#floral {
    height: 15px;
}

.flex-container, .qty-bag-wrapper, .quote-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.qty-bag-wrapper {
    color: white;
}

.quote-wrapper {
    color: white;
}

#search-div-block-compact {
    border-left: 1px solid white;
    padding-left: 5px;
    padding-top:4px;
    height: 35px;
}

    #search-div-block-compact > .downloads-link {
        vertical-align: 4px;
        color: #fff;
        text-align: center;
        display: none;
    }

    #search-div-block-compact > .whislist-icon, #search-div-block-compact > .search-opener:not(.prices-hidden) {
        color: white;
        top: -5px;
    }

    #search-div-block-compact > .whislist-icon {
            font-size: 15px;
    }

.pf-load-more-search-results-btn {
            text-decoration: none;
}

#search-div-block-compact > .account-nav-link {
    margin-left: 0;
    font-size: 10px;
    vertical-align: 2px;
}

    #search-div-block-compact > .account-nav-link > svg {
    vertical-align: 0;
    }

#search-div-block-compact > .bag-opener span {
    top: 11px;
    pointer-events: none;
    font-size: 11px;
}

.bag-opener-smallnav svg {
    font-size: 22px;
}

.secondary-nav p {
    margin-bottom: 0;
}

.lobibox-notify-wrapper .lobibox-notify.lobibox-notify-success, .lobibox-notify-wrapper .lobibox-notify.lobibox-notify-info {
    background-color: white;
    border: none;
}

.lobibox-notify-wrapper .lobibox-notify.lobibox-notify-success:hover, .lobibox-notify-wrapper .lobibox-notify.lobibox-notify-info:hover {
    background-color: white;
    border: none;
}

.lobibox-notify-wrapper.right .lobibox-notify {
    min-height: 175px;
}

.notification-image {
    position: absolute;
    padding-left: 10px;
    top: 40px;
    height: 70px;
    left: 0;
}

.lobibox-close {
    color:black;
}

.col-subcat .pf-product-name{
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.lobibox-notify-msg {
    padding-bottom: 20px;
    padding-top: 10px;
    max-height: none !important;
    font-family: Arial,sans-serif;
    font-size: 12px;
    color: #989c9f;
}

.lobibox-notify-title {
    font-family: Arial,sans-serif;
}

.lobibox-notify-title {
    padding-top: 20px;
}

.lobibox-notify-wrapper.top.right {
    right: 15px;
    top: 75px;
}

#bag-opener-notification {
    padding-top: 10px;
    padding-bottom: 10px;
    text-transform: uppercase;
    min-width: 183px;
    height: 40px;
    letter-spacing: 1px;
    display: block;
}
/*.lobibox-notify-wrapper .lobibox-notify.lobibox-notify-success*/
.lobibox-notify-body {
    color: black;
}

.lobibox-notify-icon-wrapper {
    color: black;
}

.dropdown-3.faq-dropdown:not(.spec-dropdown) {
    margin: 0;
    display: block;
}

.dropdown-faq w-icon-dropdown-toggle {
    bottom: 10px;
}

.search-div-block svg, .footer-top svg, .top-footer-col i{
    color: #fff;
}

.search-div-block a:hover svg {
    color: #989c9f;
}

#search-div-block-compact {
    transition: 0.2s;
    display: none;
}

#bag-opener {
    margin-left: 5px;
}

.link-search-div {
    height: auto;
    margin: 0px;
    margin-right: 5px;
    text-decoration: none;
}

.pf-my-orders-table .pf-btn.pf-btn-sm.pf-btn-primary {
    margin-top: 0px;
}

.pf-order-details-table {
    background-color: #FFF;
    border: none;
}

.pf-order-details-link-formatting {
    text-decoration: none;
    color: #000;
    font-weight: bold;
}

.pf-hidden.hide-order-details-btn.pf-btn-primary, .pf-hidden.view-order-details-btn.pf-btn-primary, .pf-hidden.hide-return-details-btn.pf-btn-primary, .pf-hidden.view-return-details-btn.pf-btn-primary {
    display: none;
}

.search-div-block svg, .account-nav-link-mobile i {
    font-size: 20px;
}

#bag-opener svg {
    font-size: 32px;
}

.link-search-div, .whislist-icon {
    position: relative;
    top: 4px;
    margin-right: 2px;
    margin-left: 2px;
    font-size: 14px;
}

.account-nav-link-mobile {
    position: relative;
    top: 4px;
    margin-right: 5px;
    margin-left: 2px;
}

.wishlist-main-buttons button.pf-btn.pf-btn-sm {
    font-size: 12px;
    min-width: unset;
    padding: 8px 10px;
    height: 36px;
}

.wishlist-modal-button-spacing-left {
    margin-right: 5px !important;
    width: 48%;
    font-size: 12px;
    text-transform: uppercase;
    padding: 2px 11px;
    background-color: #fff !important;
    color: #333 !important;
    border: 1px solid #333 !important;
}

.wishlist-modal-button-spacing-right {
    width: 48%;
    margin-left: 5px !important;
}

.main-footer .top-footer-icon-heading {
    text-align: unset;
}

.downloads-wrapper .addListItem2Btn {
    bottom: -50px;
}

.downloads-wrapper .faq-dropdown-list .addListItem2Btn {
    bottom: -31px;
    right: 80px;
}

.downloads-page-wrapper .mbss-listItem2-image, .downloads-page-wrapper .image-placeholder {
    min-width: 130px;
}

.home-item-wrapper img {
    width: 100%;
    height: 100%;
}

.list-item2-drop img {
    width:100%;
    height: 100%;
}

.home-item-wrapper .about-div-block:not(.redactor-in) {
    pointer-events: none;
}

.home-item-wrapper .list-item2-drop:hover + .about-div-block h2, .home-item-wrapper .list-item2-drop:hover + .about-div-block a {
    color: #989c9f;
}

.mbss-list-item-sortable .list-item2-drop:hover + .about-div-block a, .mbss-list-item-sortable .list-item2-drop:hover + .about-div-block h2 {
    color: #989c9f;
}

.errormessage {
    color: red;
    font-size: 18px;
}

.successmessage {
    color: green;
    font-size: 18px;
}

.pf-my-acc-info-wrapper p {
    margin-bottom: 0px;
}

.company-details-wrapper {
    margin-bottom: 40px;
}

.your-account {
    max-width: 970px !important;
}

.reorder-btn-item.pf-btn-primary {
    background-color: #3c3c3a;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    padding: 7px 15px;
    color: #fff;
    border: 0;
    line-height: inherit;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
    font-size: 14px;
    height: auto;
}

    .reorder-btn-item.pf-btn-primary :hover {
        background-color: #000;
    }

.td-product {
    vertical-align: top;
}

.td-return-item-quantity {
    text-align: center !important;
}

.testimonial {
    padding: 0 80px;
}

.testimonial-slider-icon.w-icon-slider-left:before, .testimonial-slider-icon.w-icon-slider-right:before {
    content: "";
}

.video-space, .sales-col p {
    margin-bottom: 0px;
}

.sales-column {
    margin-bottom: 40px;
}

.sales-col {
    margin-bottom: 0;
}

.dropdown-faq.w-icon-dropdown-toggle {
    margin: 0px;
}

.top-footer-icon-heading {
    text-align: center;
}

.pf-product-list-item .wish-list-parent {
    height: auto;
    padding: 15px 3px;
    border: none;
    position: absolute;
    right: 0;
    top: auto;
    bottom: -52px;
}

.search-list-result-item .pf-product-list-item .wish-list-parent {
    bottom: auto;
    margin-top: -15px;
}

  .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
    top: 200px !important;
}

#add-to-bag .wish-list-parent {
    min-width: 232px;
    text-align: center;
}

.testimonial-slider .w-slider-mask {
    width: 100%;
}

.colour-section {
    width: 100%;
    padding-top: 30px;
    /*padding-top:30px; removed after checking design example - SH */
}

.testimonial-section {
    padding-left: 10px;
    padding-right: 10px;
}

.testimonial-section, .main-footer-section {
    display: block;
}

.wishlist-row td.cart-total-inc-tax, .wishlist-row td.cart-total-tax, .pf-checkout-cart-table .cart-price {
    display: table-cell;
}

.pf-btn-showAddNote {
    height: 30px;
    font-size: 14px;
    text-transform: uppercase;
    padding: 2px 11px;
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
}

    .pf-btn-showAddNote:hover {
        background-color: #333;
        color: #fff;
    }

.return-submit-button {
    display: none;
}

table.cart-table th {
    font-size: 14px;
}

.cart-table img {
    float: none;
    margin-left: 0;
    margin-right: auto;
    height: 100%;
}
a.cart-first {
    display: flex;
}

.pf-checkout-cart-table td:first-child {
    width: 20%;
}

.pf-checkout-page .secondary-page-top-container {
    margin-bottom: 0;
}

form.pf-checkout-page {
    margin-bottom: 0;
}

.pf-stock-notify-me-btn, a.pf-stock-notify-me-btn {
    margin-left: 3px;
    color: #989c9f;
    font-size: 12px;
    text-decoration: none;
}

.pf-strock-notify-text {
    position: relative;
    bottom: 5px;
}

.add-to-cart {
    padding: 10px 30px !important;
}

.spec-dropdown-list.w--open {
    padding: 20px 0px 10px;
}

.pf-product-images-wrapper-static {
    justify-content: center;
}

.pf-filter-item input {
    margin-right: 0px;
}

.filter-colours .pf-colour-item {
    margin: 0px;
}

.pf-product-list-options label {
    float: left;
    font-weight: 400;
    margin-top: 8px;
    font-size: 18px;
    min-width: 60px;
}

.ecom-colour-group {
    font-size: 18px;
    font-weight: 400;
}

.filter-select-wrapper a {
    text-decoration: none;
    position: relative;
}

.filter-colours input:checked + span:after {
    border: 0.5px solid black;
    width: 100%;
}

.pf-filter-item.pf-colour-item {
    padding-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.ecom-colour-group {
    margin-right: 30px;
}

.breadcrumb .pf-normal-link, .non-clickable-breadcrumb {
    padding: 0px 5px;
    font-size: 12px;
    font-weight: 700;
}

.breadcrumb span:last-of-type .pf-normal-link, #pf-product-details-page .breadcrumb div .pf-normal-link:last-of-type {
    color: #989c9f;
    font-weight: 400;
}

.pf-recent-related-product-item, .pf-recent-related-product-item:hover {
    box-shadow: none;
}

.pf-stock-count, .pf-stock-summary-backorder, .pf-product-list-item .pf-stock-info {
    text-transform: none;
}

.colour-section {
    position: relative;
}

.reference-type.checkout-order-notes {
    margin-top: 0px;
}

.light-grey-text.add-another-item-text {
    margin-top: 15px;
    margin-top: 0px;
}

.reference-type.print.checkout-print {
    margin-bottom: 15px;
    margin-top: 0;
    float: right;
}

.cart-container .total-price-container .price.total {
    margin-top: 30px;
    margin-bottom: 20px;
}

.price-form .w-col {
    padding-left: 10px;
    padding-right: 10px;
}

#pf-product-listing .pf-product-name {
    line-height: 18px;
}

.pf-modal-wishlist .pf-btn.pf-btn-sm {
    margin-left: auto;
    margin-right: auto;
}

.edit-wishlist-name-btn {
    text-decoration: none;
}

.delete-wishlist-btn {
    margin-top: 0;
    margin-right: 20px;
    text-decoration: none;
}

.wishlist-image-td {
    width: 20%;
}

.account-application-section {
    padding-top: 20px;
}

.pf-product-main-image-wrapper .product-media.w--tab-active {
    display: block !important;
}

#RelatedRecentlyProducts .testimonial-header {
    margin-bottom: 25px;
}

.flex-container.w-row:before {
    display: flex;
}

.about-div-block a, .product-heading a {
    text-decoration: none;
    font-weight: 700;
}

.header-no-padding.downloads {
    min-width: 376px;
}

.testimonial-slider .w-slider-arrow-left, .testimonial-slider .w-slider-arrow-right {
    top: -110px;
}
.barclays-form {
    display:flex;
    justify-content:center;
}
.barclays-form input{
    margin:0 10px;
}
@media (max-width:430px) {
    .header-no-padding.downloads {
        min-width: 245px;
        width: 100%;
    }
}

@media (max-width:1285px) {
    #pf-product-list-page {
        padding-left: 20px;
        padding-right: 20px;
    }

        #pf-product-list-page .breadcrumb {
            padding-left: 2px !important;
        }
}

@media (min-width:1200px) {
    div .container-wishlist.w-container {
        max-width: 1000px;
    }
}

@media (max-width: 1300px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 235px !important;
    }
}

@media (max-width: 1200px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 215px !important;
    }
}

@media (min-width: 1200px) {
    .fairs-and-events-section {
        margin-top: 20px;
    }
}

@media (min-width:991px) {
    .hide-link-desktop {
        display:none;
    }
    .account-nav-link-mobile {
        display: none;
    }

    .brand-logo-mobile {
        display: none;
    }

    .pf-product-images-wrapper-slider {
        display: none;
    }

    .tabs, .w-form.results-filter-wrapper, .w-row.hide {
        display: block;
    }
}

@media (max-width: 990px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 185px !important;
    }

    #pf-product-details-page .w-container .w-row {
        margin-left: 0;
        margin-right: 0;
    }

    .my-account-link {
        display: none;
    }

    .logo-section .brand.w-nav-brand.logo {
        display: none;
    }

    .search-div-block {
        margin-top: 24px;
    }

    .brand-logo-mobile-wrapper {
        width: 60%;
        padding-left: 10px;
    }

    .brand-logo-mobile {
        position: relative;
    }

    .pf-product-images-wrapper-slider {
        display: block;
    }

    .tabs, .w-form.results-filter-wrapper, .w-row.hide {
        display: none;
    }

    #scrollUp {
        display: none !important;
    }
}

@media (max-width: 946px) {
    #pf-product-listing .pf-product-list-options {
        float: left;
        margin-top: 15px;
    }

    .filter-select-wrapper .w-col {
        width: 100%;
    }
}

@media (max-width: 915px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 160px !important;
    }
}

@media (max-width: 850px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 150px !important;
    }
}

@media (min-width: 770px) {
    .header-section.header-width-30 {
        width: 30%;
    }
}

@media (max-width: 769px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 140px !important;
    }

    .flex-container .w-col {
        width: 100%;
        text-align: center;
    }

    .home-item-wrapper {
        margin-bottom: 0px;
    }

    .secondary-page-top-container {
        max-width: 100% !important;
    }

    .header-section {
        padding: 0 30%;
    }

    .about-us-center .header-section {
        width: 100%;
    }

    #returnEditArea .w-col-6 {
        width: 100%;
    }

    #returnEditArea .w-col.form-col-right {
        padding-left: 0px;
    }

    ._1250w-div.main-footer .w-row {
        display: flex;
    }

    .secondary-nav-col-right.w-col.w-col-8.secondary-page-top-container, ._1250w-div.about-us {
        margin-top: 0px;
        padding-top: 0px;
    }

    .secondary-nav-col-right.w-col.w-col-9.secondary-page-top-container.terms {
        width: 100%;
        position: relative;
    }

    .header-section.header-width-30 {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .barclays-form {
        align-items: center;
        flex-direction: column;
    }
    .barclays-form input {
        margin: 10px 0;
    }
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 50% !important;
    }

    .secondary-nav-col-right.w-col.w-col-8.secondary-page-top-container, ._1250w-div.about-us {
        margin-top: 0px;
        padding-top: 0px;
    }

    .secondary-nav-col.w-col.w-col-3 {
        width: 100%;
        position: relative;
    }

    .page-nav-header {
        margin-bottom: 0px;
    }

    #create-account-form .w-col {
        padding-left: 0px;
    }

    .company-details-section {
        padding: 20px 0;
    }

    .sign-in-col, .accounts-container.new-password {
        padding-right: 10px;
        padding-left: 10px;
    }

    .cart-container .pf-quick-order-quick-entry {
        margin-bottom: 15px;
        padding-left: 10px;
    }

    .this-wish-list {
        padding-left: 0px;
    }

    .align-right .add-product-view-range-btn {
        margin-bottom: 10px;
    }

    .total-price-container .align-right {
        float: none;
    }

    .filter-colour-wrapper.filter-colours {
        display: flex;
        flex-wrap: wrap;
    }

    #filtersColourOnly .filter-colours .pf-colour-item {
        margin-top: 5px;
        margin-bottom: 5px;
    }


    #search-results-wrapper .w-col.w-col-3.border-spaced {
        width: 50%;
        padding-left: 5px;
        padding-right: 5px;
    }
}

@media(max-width: 720px) {
    .secondary-nav-col.w-col.w-col-3.about-us-center {
        position: relative;
    }

    .hide-secondary-nav-on-mobile{
        display:none;
    }
}

@media (max-width: 600px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 45% !important;
    }

    .header-section {
        padding: 0 10%;
    }

    .question-link {
        white-space: normal;
        padding: 10px 15px;
    }

    /*.faq-dropdown {
            height: 55px;
        }*/

    .button-check-out:not(#makePaymentBtn) {
        float: none;
    }

    .cart-first img {
        width: 75px;
        height: 100%;
    }
}

  @media (max-width: 515px){
    .add-to-basket-remove-seperator{
        display: none;
    }

    .pf-add-to-cart-btn.pf-add-to-cart-btn-site, .remove-from-this-wishlist-btn {
        display: block;
        padding-top: 5px;
    }
  }
  
  
  
  @media (max-width: 479px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 40% !important;
    }

    .question-link {
        white-space: normal;
        padding: 0px 15px;
    }

    .h1-header.section, .h1-header.section.shopping {
        width: 100%;
    }

    ._1250w-div.main-footer .w-row {
        display: unset;
    }

    .pf-btn-showAddNote {
        height: auto;
    }

    .your-account.padding-0-maxwidth-479 {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .brand-logo-mobile img {
        padding-top: 4%;
    }

    .price.cart-price {
        display: none;
    }

    .mobile-cart-price {
        display:table-cell
    }

    .edit-wishlist-name-btn {
        display:block;
    }

    .wishlist-main-buttons button.pf-btn.pf-btn-sm {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
    /*@media (max-width: 474px){
        .brand-logo-mobile img {
            padding-bottom:8%;
        }
    }*/
    @media (max-width:450px) {
    #search-product-count-label {
        padding-left: 5px;
    }

        #pf-product-list-page {
            padding-left: 0;
            padding-right: 0;
        }
}

@media (max-width: 425px) {
    .brand-logo-mobile img {
        padding-top: 5%;
    }

    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 50% !important;
    }

    #scrollUp {
        font-size: 30px;
        height: 30px;
        width: 30px;
        right: 3%;
    }

    #pf-gallery-image-product-actions {
        flex-direction: column;
    }

        #pf-gallery-image-product-actions > * {
            margin-top: 10px;
            margin-bottom: 10px;
        }

    #ui-id1 {
        left: 0 !important;
    }

    #prod-search-tbx {
        line-height: 0;
    }

    .pf-terms-wrapper {
        margin-bottom: 10px;
    }

    .recently-viewed-section.cart-section.checkout {
        padding-top: 0px;
    }

    #validNotes {
        display: none;
    }

    .pf-detail-view-add-cart-wrapper {
        display: flex;
    }

    .pf-detail-view-add-cart-wrapper .pf-add-to-cart-btn {
        font-size: 12px !important;
    }

    .pf-promotion-container {
        font-size: 12px;
    }
}

@media (max-width: 372px) {
    .brand-logo-mobile img {
        padding-top: 6%;
    }
}

@media (max-width: 356px) {
    .brand-logo-mobile img {
        padding-top: 7%;
    }
}

@media (max-width: 292px) {
    .pf-product-list-item .new-left-bottom, .pf-product-list-item .new-right-bottom {
        top: 40% !important;
    }
}

@media (max-width: 1250px) {
    ._1250w-div {
        width: 100%;
    }
    ._1250w-div.with-slider {
        width: 100%;
    }
    .events-form {
      width: 100% !important;
    }
}

@media (min-width: 1200px) {
    .search-container.w-container {
        max-width: 940px;
        padding: 0 20px;
    }
}

@media (max-width: 991px) {
    .header-container h1 {
        line-height: 30px;
    }

    .the-website {
        padding-top: 107px;
    }

    .search-section {
        margin-top: -70px;
    }


    .the-website.pf-promotion-strip-enabled {
        padding-top: 147px;
    }

    .search.w-form.search-opener {
        display: none;
    }

    .sidenav-btn-wrapper {
        display: block;
    }
}

@media (max-width: 767px) {
    .home-item-wrapper, .home-col {
        text-align: center;
    }

    .footer-col-div {
        width: 50%;
    }

    ._1250w-div.about-us {
        padding-right: 0px;
    }

    .header-section {
        width: 100%;
    }

    /*.nav-section.white-line {
        top: 98px;
    }*/

    .filter-select-wrapper .w-col-6, .filter-select-wrapper .w-col-4, .filter-select-wrapper .w-col-2 {
        width: 100%;
    }

        .filter-select-wrapper .w-col-2 .pf-normal-link {
            padding-left: 15px;
        }
}

@media (max-width: 486px) {
    .footer-col-div {
        width: 100%;
    }
}
    /* Returns form */
    .w-col.form-col-left {
    padding-right: 5px;
}

.w-col.form-col-right {
    padding-left: 5px;
}

.form-input-label {
    color: #989c9f;
    font-weight: 400;
    text-align: left;
}

.return-form {
    text-align: left;
}

    .return-form .pf-qty-btn, .return-form .pf-qty-select {
        height: 38px;
    }

    .return-form .pf-qty-wrapper {
        margin-bottom: 10px;
    }

    .return-form input[type=file] {
        display: none;
    }

    .return-form label.w-button {
        font-weight: 400;
        float: none;
    }

table.return-table {
    width: 100%;
    text-align: left;
    margin-bottom: 40px;
}

    table.return-table tr {
        border-bottom: 1px solid;
    }

    table.return-table th, table.return-table td {
        padding: 10px 0;
    }

    table.return-table th {
        line-height: 1;
        text-transform: uppercase;
        font-weight: bold;
    }

    table.return-table .return-item-image {
        float: left;
        width: 150px;
        margin-right: 20px;
        margin-bottom: 10px;
    }

    table.return-table .return-item-action {
        margin-bottom: 10px;
    }

    table.return-table .desc-col {
        font-size: 12px;
        color: #000;
        padding-bottom: 0;
    }

        table.return-table .desc-col h5 {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 0;
            text-transform: uppercase;
        }

    table.return-table .quantity-desc {
        display: none;
        font-weight: bold;
    }

    table.return-table .quantity-col {
        width: 20%;
        text-align: center;
    }

    table.return-table td.desc-col {
        vertical-align: top;
    }

    table.return-table td.quantity-col {
        font-weight: bold;
    }

    table.return-table td.desc-col > div:last-child:not(.return-item-attachment-thumbnails) {
        margin-bottom: 10px;
    }

    table.return-table .return-item-label {
        color: #989c9f;
    }

    table.return-table .return-item-invoice-no, table.return-table .return-item-reason, table.return-table .return-item-attachments, table.return-table .return-item-attachment-thumbnails {
        margin-left: 170px;
    }

.return-item-attachment-thumbnails {
    display: flex;
    flex-wrap: wrap;
}

.return-item-attachment-thumbnail {
    width: 100px;
    height: 100px;
    border: 1px solid #ccc;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

table.return-table .return-item-attachment-thumbnail {
    width: 40px;
    height: 40px;
}

.return-item-attachment-thumbnail > img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.return-item-attachment-thumbnail > svg {
    font-size: 80px;
}

table.return-table .return-item-attachment-thumbnail > svg {
    font-size: 30px;
}

.return-item-attachment-remove {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    cursor: pointer;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    font-size: 12px;
}

#returnItemSaveButton {
    margin-bottom: 15px;
}

#returnSuccessArea {
    display: none;
}

.return-success-message {
    color: #2d7648;
}

.add-another-item-text {
    padding: 5px;
    padding-left: 10px;
}

.nav-section.white-line {
    border-top: 1px solid #fff;
}

.product-heading {
    margin-top: 5px;
    margin-bottom: 25px;
}

.top-footer-icon-heading p {
    margin-bottom: 0;
}

.home-item-wrapper:not(:nth-last-child(-n+2)) {
    margin-bottom: 20px;
}
.fairs-and-events .home-item-wrapper:not(:nth-last-child(-n+2)) {
    margin-bottom: 0px;
}
.fairs-and-events-section.bottom-section .fairs-and-events .mbss-listItem2-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.w-slider-arrow-left.hidearrow, .w-slider-arrow-right.hidearrow {
    display: none;
}

.w-slider-arrow-left, .w-slider-arrow-right {
    width: 30px;
}

    .w-slider-arrow-right.product-detail-slider-arrow {
        right: -25px;
        bottom: 45px;
    }

    .w-slider-arrow-left.product-detail-slider-arrow {
        left: -35px;
        bottom: 45px;
    }
    /* auto suggest styles */
    #ui-id-1 {
        background-color: #fff;
        font-size: 14px;
        border: 1px solid #333;
        border-top: none;
        margin-top: 10px;
        border-radius: 0;
        color: #333;
}

.ui-menu .ui-menu-item {
    padding: 2px;
}

    .ui-menu .ui-menu-item a:hover {
        color: #f45c71;
        border: 1px solid #fff;
        cursor: pointer
    }
    /* breadcrumb */
    .breadcrumb a:last-child {
    font-weight: normal;
    color: #989c9f;
}
    /* responsive styles */
    @media (max-width: 767px) {
    .w-col.form-col-left {
        padding-right: 0;
    }

    .home-item-wrapper:not(:nth-last-child(-n+1)) {
        margin-bottom: 20px;
    }

    .w-col.form-col-right {
        padding-left: 0;
    }
}
    /* fixes to product details */
    #pf-product-details-page .pf-product-image-thumb.w--current {
    border: none;
}
    /* fixes to product details */
    .add-to-basket-col-left {
    align-items: center;
}

.pf-product-image-thumb {
    margin-right: 40px;
    display: flex;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    -webkit-box-pack: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    -ms-flex-pack: start;
}

.pf-prod-dimenstions {
    color: #3c3c3a;
    font-size: 14px;
    line-height: 16px;
    margin: 10px 0;
}

.pf-prod-dimension-ch-mm{
    margin-left: -4px;
}

.pf-stock-info.pf-block {
    margin-bottom: 0;
}

#pf-product-details-page .pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
    min-width: 190px;
}

#add-to-bag .wish-list-parent, .add-product-view-range-btn {
    min-width: 258px;
}

.spec-dropdown-list.w--open {
    padding-top: 5px;
    border-top: none;
    margin-bottom: 15px;
}

.spec-dropdown {
    border-bottom: none;
    max-width: 258px;
    margin-bottom: 0;
    margin-top: 20px;
}

    .spec-dropdown .w-dropdown-toggle {
        border-bottom: 1px solid #989c9f;
    }

    .spec-dropdown .w-icon-dropdown-toggle {
        top: -6px;
    }

.pdf-div-block {
    margin-top: 4px;
}

.pf-product-list-item, .pf-recent-related-product-item {
    box-shadow: none;
}

    .pf-product-list-item:hover, .pf-recent-related-product-item:hover {
        box-shadow: none;
    }

#pf-related-recent-products-wrapper {
    margin-top: 20px;
}

#pf-related-recent-products-wrapper .w-col {
    padding-left: 15px;
    padding-right: 15px;
}

.pf-tabs-wrapper .pf-recent-related-product-item {
    padding-bottom: 5px;
}

.pf-related-recent-tab-content {
    padding-bottom: 0;
}

.product-detail-bottom-tabs {
    margin-top: 0;
    margin-bottom: 0;
}

.pf-prod-dsc-outer {
  max-height: 95px;
  overflow: hidden;
  border-bottom: 1px solid #989c9f;
}

#readMoreBtn {
    text-decoration: underline;
    color: #989c9f;
    font-size: 12px;
    line-height: 16px;
    display: none;
    margin-top: 4px;
}

.add-product-view-range-btn {
    display: table;
}

#pf-product-details-page .pf-detail-view-add-cart-wrapper {
    margin-top: 17px;
    min-width: 250px;
}
    /* product list fixes */
    #pf-product-listing .w-col .ecom-colour-group {
    padding-left: 0;
}

.pf-filter-item.pf-colour-item {
    padding: 0;
}

#pf-product-list-page .breadcrumb {
    padding-left: 0;
    margin-left: 0;
}

.breadcrumb .pf-normal-link:first-child {
    padding-left: 0;
}
    /* basket fixes */
    .pf-checkout-cart-table {
    margin-top: 0;
}

.total-price-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.pf-checkout-cart-table th {
    padding: 5px 10px;
}

.pf-checkout-cart-table .cart-description {
    vertical-align: top;
}

.product-mini-descriptions.left {
    text-decoration: none;
}
    /* checkout fixes */
    .price-form a.pf-normal-link {
    color: #075c9c;
    text-decoration: none;
}

.pf-terms-wrapper {
    background-color: #fff;
    padding: 0;
}

    .pf-terms-wrapper label {
        font-weight: normal;
    }

    .pf-terms-wrapper .tcLabel {
        margin-bottom: 5px;
        display: inline;
    }

.cart-container.w-container {
    padding-top: 20px;
}
    /* register page fixes */
    .register-page {
    margin-top: 20px;
}

.register-heading-text {
    max-width: 580px;
}

.register-btn {
    margin-top: 20px;
}

.register-bottom-text {
    max-width: 750px;
}
    /* sign up page fixes */
    .w-container.sign-up-container {
    max-width: 940px;
}

.signup-terms-cbx-wrapper a {
    color: #075c9c;
    text-decoration: none;
}

@media (max-width: 575px) {
    table.return-table .quantity-col {
        display: none;
    }

    table.return-table .quantity-desc {
        display: block;
    }

    table.return-table .return-item-image {
        width: 100px;
    }

    table.return-table .return-item-invoice-no,
    table.return-table .return-item-reason,
    table.return-table .return-item-attachments,
    table.return-table .return-item-attachment-thumbnails {
        clear: left;
        margin-left: 0;
    }
}
    /* Gallery (create the style) */
    .style-container {
    column-gap: 20px;
    column-count: 4;
    column-fill: balance;
}

.style-item {
    margin-bottom: 20px;
}

.w-icon-slider-left::before, .w-icon-slider-right::before {
    display: none;
}

._1250w-div.style-slide {
    width: auto;
    height: auto;
    max-width: 1000px;
    max-height: 1000px;
}
.pf-nav-image img {
    max-width:270px;
    max-height:270px;
}
@media (min-width: 2290px) {
    #prod-search-tbx {
        height: 55px;
    }
}

@media (max-width: 1290px) {
    .w-container {
        padding-left: 20px;
        padding-right: 20px;
    }

    #pf-product-list-page .w-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 1199px) {
    ._1250w-div.style-slide {
        max-width: 800px;
        max-height: 800px;
    }
}

@media (max-width: 991px) {
    .style-container {
        column-count: 3;
    }

    ._1250w-div.style-slide {
        max-width: 600px;
        max-height: 600px;
    }

    .nav-link.nav-dropdown, .w-nav-link.nav-link.w--nav-link-open {
        font-size: 15px;
        margin-top: 5px;
        margin-bottom: 5px;
        font-weight: 100;
    }

    .nav-menu.w-nav-menu > div, .nav-menu.w-nav-menu > a {
        border-bottom: 1px #eeeeee solid;
    }

    .nav-link.nav-dropdown.w--open::before {
        content: '\2212';
    }

    .nav-link.nav-dropdown::before {
        bottom: 5px;
        content: '\002B';
        font-size: 24px;
        font-family: Georgia,Times,'Times New Roman',serif;
        text-align: right;
        float: right;
    }

    .w-nav-overlay {
        overflow-y: auto;
        max-height: 300px;
        z-index: 999;
    }

    .megamenu-col.w--open {
        padding: 5px;
    }
}

@media (max-width: 767px) {
    .style-container {
        column-count: 2;
    }

    ._1250w-div.style-slide {
        max-width: 450px;
        max-height: 450px;
    }
}

@media(max-width:700px) {
    .brand {
        max-width: 160px;
    }
}

@media (max-width: 575px) {
    .style-container {
        column-count: 1;
    }

    ._1250w-div.style-slide {
        width: 60%;
        height: auto;
    }

    .pf-filter-item.pf-colour-item {
        align-items: inherit;
    }

    .ecom-colour-group {
        margin-right: 10px;
    }

    #filtersColourOnly .filter-colours .pf-colour-item {
        margin-right: 5px;
    }

    #pf-product-listing .pf-product-list-options {
        padding-left: 0;
    }

    .ecom-colour-group, .pf-product-list-options label {
        font-size: 15px;
    }

    #pf-product-listing .w-container {
        padding-left: 0;
        padding-right: 0;
    }
} 
  
  @media(max-width:475px) {
    .logo-section {
        padding-bottom: 0;
    }

    .brand {
        max-width: 100%;
    }
}

@media(max-width:450px) {
     #pf-product-listing .w-col {
        padding-left: 0;
        padding-right: 0;
    }
}

@media(max-width: 415px) {
    .lobibox-notify-wrapper.top.right {
        right: 0px;
    }
}

  @media(max-width:394px){
    .add-to-wishlist-btn {
        margin-right: 0;
    }
  }

  @media(max-width: 334px){
    .pf-detail-view-add-cart-wrapper .add-to-cart {
        padding-left: 25px !important;
        padding-right: 25px !important;
        width: 169px;
    }
  }

  
  /* Wishlist popup */
    .pf-modal-wishlist.remodal {
    max-width: 900px;
    padding: 20px;
    padding-top: 25px;
}

.pf-modal-wishlist .remodal-close,
.pf-modal .remodal-close {
    right: 20px;
    left: unset;
    top: 20px;
    z-index: 10;
}

    .pf-modal-wishlist .remodal-close:before,
    .pf-modal .remodal-close:before {
        font-size: 50px;
    }

.wishlistCheckboxList .checkbox {
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.container-wishlist {
    text-align: left;
}

.pf-modal-wishlist-list h3,
.pf-modal h3 {
        font-size: 22px;
        margin-top: 0;
        line-height: 44px;
}

.wishlistCheckboxList label {
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
    padding: 12px;
}

.wishlist-main-buttons {
    margin-top: 25px;
}

.w-col.w-col-12.wishlist-sub-total {
    font-size: 14px;
    text-transform: uppercase;
    float: right;
    font-weight: bold;
    line-height: 20px;
    color: #333;
    margin-top: 0;
    padding: 0;
    text-align: right;
    margin-bottom: 15px;
}
    /* custom qty buttons */
    .pf-qty-btn.pf-qty-btn-minus {
    border-left: 1px solid #ccc;
}

.pf-qty-btn {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}

    .pf-qty-btn.pf-qty-btn-plus {
        border-right: 1px solid #ccc;
    }

.pf-qty-select {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    background-color: white;
}

.wish-list-group-wrapper {
    margin-top: 50px;
}
    /* custom select */
    select.w-select {
    color: #000;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 1px solid #000;
}

.select-wrapper {
    position: relative;
}

    .select-wrapper:after {
        font-family: FontAwesome;
        content: '\f107';
        font-size: 17px;
        position: absolute;
        top: 9px;
        right: 20px;
        color: #434B67;
        pointer-events: none;
    }

select::-ms-expand {
    display: none;
}

.account-div-block .account-link span {
    position: relative;
    top: 4px;
    margin-right: 5px;
    max-height: 21px;
}

.search-block .search-close svg {
    font-size: 32px;
}

#expanded-search-tbx {
    min-height: 45px;
}

#add-to-bag .wish-list-parent, .add-product-view-range-btn {
    min-width: 250px;
    height: 38px;
    min-height: 38px;
}

#add-to-bag .wish-list-parent {
    padding-left: 0;
    padding-right: 0;
}

.dropdown-3 p {
        margin-bottom: 5px;
}

.dropdown-3 {
    margin-bottom: 0px;
}

.blog-items .product-code {
    margin-top: 5px;
}

#pf-product-details-page .pf-add-to-cart-btn.pf-btn.pf-btn-sm.pf-btn-primary.pf-btn-with-qty {
    min-width: 182px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.horizontal-scroll-box {
    overflow-x: hidden;
}
#pf-recent-slider, #pf-related-slider {
    overflow-x: unset;
    position: static;
}
.register-page-container {
    margin-bottom: 10px;
}

#pf-gallery-image-product-actions {
    margin-bottom: 0px;
}

.banner-link {
    position:absolute;
    width:100%;
    height:100%;
}

._1250w-div.videos {
height:auto;
}

.company-details-section.no-border {
    border-top:none;
}

.company-details-section.no-top-padding {
    padding-top: 0;
}

.access-code-separator {
    border-top: 1px solid #000;
}

.accounts-body-copy.register-bottom-text {
    margin-top:40px;
}

.results-wrapper {
    display:flex;
    flex-wrap:wrap;
}

.search-list-result-item, .product-list-item {
    flex: 0 0 25%;
    flex-direction: row;
}

.nav-section.white-line {
    position:relative;
}

.banner-text-large {
    font-size: 50px;
    font-family: 'Perpetua Normal',sans-serif;
    text-align: center;
    letter-spacing: 6px;
    line-height: 50px;
}

.banner-text-medium {
    font-size: 24px;
    font-family: 'Perpetua Normal',sans-serif;
    text-align: center;
    letter-spacing: 6px;
    line-height: 50px;
}

.banner-text-small {
    font-size: 20px;
    font-family: 'Perpetua Normal',sans-serif;
    text-align: center;
    letter-spacing: 6px;
    line-height: 30px;
}

#main-nav .nav-link.w-nav-link {
    margin-top: 8px;
    margin-bottom: 9px;
}

@media (max-width: 767px) {
    .banner-text-large {
        font-size: 30px;
        line-height: 30px;
    }

    .banner-text-medium {
        font-size: 20px;
        line-height: 30px;
    }

    .banner-text-small {
        font-size: 16px;
        line-height: 20px;
    }

    .search-list-result-item, .product-list-item {
        flex: 0 0 50%;
    }

    .pf-nav-images {
        display: none;
    }

    .pf-order-details-table img {
        height: inherit;
    }

    .pf-order-details-table .reorder-btn-item.pf-btn-primary {
        font-size: 10px;
        line-height: 16px;
        padding: 4px 6px;
    }

    .pf-order-details-table td {
        width: 10%;
        text-align: center;
    }

    .pf-order-details-table th {
        text-align: center;
    }

    .testimonial-slide {
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 1199px) {
    .pf-nav-images img {
        height: inherit;
        max-height: 250px;
    }
}

@media (max-width: 1107px) {
    .pf-nav-images img {
        height: inherit;
        max-height: 200px;
    }
}

  .margin-top-12 {
    margin-top: 12px !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.nav-section.white-line.w-nav ._1250w-div {
    position:absolute;
    left:0;
    right:0;
    margin:auto;
    top:0;
}

.qty-bag-wrapper {
    position:relative;
    font-size: 15px;
}

.bag-opener span {
    left: 0;
    right: 0;
    text-align: center;
    top:11px;
}

.nav-section.white-line.w-nav .w-nav-link.nav-link #main-nav {
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
}

#search-div-block-compact .bag-opener span {
    top:8px;
}

_:-ms-fullscreen, :root .nav-section.white-line.w-nav .w-nav-link.nav-link {
    flex-basis: auto;
}

.nav-section.white-line.w-nav #main-nav .w-dropdown, .nav-section.white-line.w-nav #main-nav .w-nav-link {
    flex-grow: 1;
    flex-shrink: 0;
    text-align: center;
}

_:-ms-fullscreen, :root .nav-section.white-line.w-nav .w-dropdown {
    flex-basis: auto;
}

.megamenu-col.megalist-loggedin.w-dropdown-list.mega-nav-nav {
text-align:left;
}

.hamburger-menu-icon {
    padding:3px 5px;
}

.social-media {
    text-align: center;
    margin-top: 15px;
}

.social-media a{
    color: #fff;
}

.footer-copyright {
    height: auto;
}

.footer-copyright .copyright-text{
    margin-top: 5px;
}

.social-link {
    margin-right: 10px;
    margin-left: 10px;
    display: inline-block;
}

@media screen and (max-width: 479px) {
    .hide-mobile {
        display: none;
    }
}

@media screen and (min-width: 480px) {
    .show-mobile {
        display: none;
    }
}
@media screen and (max-width: 425px) {
    .pf-product-list-item-img-wrapper {
        max-width: unset;
    }
}
.fair-event-block.about-div-block {
    bottom: 125px;
}

.fairs-and-events-section.bottom-section {
    padding-top:60px;
}

.pf-wishlist-cart-table .wishlist-total-header {
    width: 125px;
}
.nav-hamburger-wrapper .bag-opener-smallnav {
    margin-left: 5px;
    padding-top: 3px;
}
.nav-hamburger-wrapper .bag-opener-smallnav svg {
    font-size: 28px;
}
.nav-hamburger-wrapper .bag-opener-smallnav .cart-total-qty {
    top:9px;
}
.nav-hamburger-wrapper .search-opener, .nav-hamburger-wrapper .quote-opener {
    top: -5px;
    position:relative;
}
.nav-hamburger-wrapper .search-opener svg {
    font-size: 20px;
}
.nav-hamburger-wrapper .quote-opener svg {
    font-size: 20px;
}

.wishlist-collapse-button {
    cursor: pointer;
}

.wishlist-collapse {
    display: none;
}

.newsletter-section {
    width: 100%;
    text-align: center;
    margin-bottom: 45px;
}

.subscribe-title h1 {
    padding-bottom: 10px;
}

.subscribe-paragraph p {
    margin-bottom: 25px;
    text-transform: none !important;
}

.subscribe-wrapper {
    width: 40%;
    margin: 0 auto;
    padding-bottom: 40px;
}

.subscribe-input {
    width: 65%;
    display: inline-block;
    margin-right: 5px;
}

.logged-in-signup-input {
    width: 65%;
    display: inline-block;
    margin-right: 5px;
}

#signup-btn {
    width: 180px;
    display: inline-block;
    background-color: #3c3c3a;
    margin-left: -15px;
}

    #signup-btn:hover {
        background-color: #000;
    }

#logged-in-signup-btn {
    width: 180px;
    display: inline-block;
    background-color: #3c3c3a;
    margin-left: -15px;
}

    #logged-in-signup-btn:hover {
        background-color: #000;
    }

#redactor-modal {
    position: -webkit-sticky !important;
    position: sticky !important; 
    top: 10% !important;
    margin-top: 0 !important;
}

@media screen and (max-width: 767px) {
    .subscribe-wrapper {
        width: 100%;
    }

    .subscribe-input {
        width: 90%;
        float: none !important;
    }

    #signup-btn, .barclays-button {
        float: none !important;
    }

    .logged-in-signup-input {
        width: 90%;
    }
    .logged-in-barclays-input {
        display: inline-block;
        float: none !important;
    }
    #logged-in-signup-btn {
        float: none !important;
    }
}
.about-section a span:hover, div.about-us p a:hover, .about-section .mbss-text a:hover, .about-section p a:hover,
.about-section p a span:hover, .account-application-section p a span:hover, .account-application-section .mbss-text a:hover,
form#create-account-form .sign-up-container a:hover, .accounts.register-page a:hover, .form-wrapper.parentsvoice-form p a:hover,
.home-main-section .w-container a span:hover, .slavery-section p a span:hover {
    color: rgb(152, 156, 159) !important;
}
.newsletter-signup-checkbox {
    margin-right: 5px;
}

.download-link a {
    margin-bottom: 10px;
    color: #075c9c;
    text-decoration: none;
}

.download-link a:hover {
    color: #989c9f;
}

.nav-section.white-line > .w-nav-link.nav-link,
.nav-section.white-line > .w-dropdown {
    display: none;
}
.recaptchaErrorMessage {
    color: #ff0000;
}
#sync-image-zips-btn {
    display: inline-block;
    float: left;
    margin-right: 5px;
}
#productImageZipLetterSelect {
    float: left;
}

.return-submit-button.button-check-out.returns {
    margin-right: 10px;
    margin-bottom: 10px;
    float: none;
}
.return-submit-button-wrapper {
    margin-bottom: 30px;
}

form#returnForm .submit-progress {
    display: inline-block;
    line-height: 38px;
}
form#returnForm .submit-progress .fa-spinner,  .container-wishlist .fa-spinner{
    animation: spin 2s linear infinite;
    display: inline-block;
    font-size: 30px;
    vertical-align: middle;
    margin-right: 10px;
}
.container-wishlist .fa-spinner {
    display:none;
}
form#returnForm .submit-progress span {
    vertical-align: middle;
}
.search-div-block .account-nav-link-mobile, .search-div-block .w-inline-block.whislist-icon {
    color: #fff;
}
.header.shrink .nav-hamburger-wrapper .quote-wrapper .fa-lg {
    vertical-align: -0.2em;
}

#list-products-wrapper .pf-product-list-item .fa-heart.empty-wishlist, #list-products-wrapper .pf-product-list-item .fa-heart.fa-2x {
    font-size: 1.5em;
}
.returnItemInvoiceNoPrefix {
   display:flex;
   align-items:center;
   background:#fff;
   border:1px solid #a0a0a0;
   border-radius: 4px;
   padding-left: 0.5rem;
   overflow:hidden;
}

.returnItemInvoiceNoPrefix .returnItemInvoiceNoPrefixSpan {
   font-weight:300;
   font-size:14px;
   color:#999;
}

.returnItemInvoiceNoPrefix input {
   flex-grow:1;
   background:#fff;
   border:none;
   outline:none;
   padding:0.5rem;
}

.returnItemInvoiceNoPrefix:focus-within {
   border-color:#777;
}
.home-main-section .colour-section.collectionItems .mbss-listItem2-wrapper {
    position: relative;
    min-height: 30px;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-auto-rows: minmax(min-content,max-content);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
}
.home-main-section .colour-section.collectionItemsLoggedOut .mbss-listItem2-wrapper {
    position: relative;
    min-height: 30px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-auto-rows: minmax(min-content,max-content);
    grid-column-gap: 1px;
    grid-row-gap: 1px;
}
.colour-section.collectionItems .mbss-listItem2-wrapper .w-col-4, .colour-section.collectionItems .mbss-listItem2-wrapper .w-col-6,
.colour-section.collectionItemsLoggedOut .mbss-listItem2-wrapper .w-col-4, .colour-section.collectionItemsLoggedOut .mbss-listItem2-wrapper .w-col-6 {
    width: 100%;
}
.collectionImgs.w-row:before, .collectionImgs.w-row:after {
    content: unset !important;
}
a.cart-first {
    display: flex;
}
.home-main-section .newsletter-section a span:hover, .home-main-section downloads-page-wrapper a span:hover, 
.company-details-section a span:hover, .about-section a span:hover {
    color: #989c9f !important;
}

.pf-modal p {
    margin-left: 50px;
    margin-right: 50px;
}
.pf-modal .pf-btn.pf-btn-sm.pf-btn-secondary {
    background-color: #fff;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: inline-block;
    color: #333;
    border: 1px solid #333;
    line-height: inherit;
    cursor: pointer;
    border-radius: 0;
    text-decoration: none;
}
.pf-modal button.pf-btn.pf-btn-sm {
    font-size: 12px;
    min-width: unset;
    padding: 8px 10px;
    height: 36px;
    margin: 5px;
}

@media screen and (max-width: 479px) {
    .remodal.pf-modal {
        padding: 50px 20px 30px;
    }

    .pf-modal p {
        margin-left: 20px;
        margin-right: 20px;
    }
}